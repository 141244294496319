import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import Loader from "./Loader";
import Navbar from './Navbar';
import Footer from './Footer';
import { dispatchLoadingStatus } from '../utils';
import { role,weburl } from "../constants";
import DataTable from "react-data-table-component";
import { ReportedSearchPostAPI, PoststatusAPI } from '../actions/globalactions';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addDays } from 'date-fns';
import pageloadloader from "../asset/images/page-load-loader.gif";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import swal from 'sweetalert';
import { Img } from 'react-image';
import user09 from "../asset/images/user1.svg";
import Modal from "react-responsive-modal";
import ReactTooltip from "react-tooltip";
import parse from 'html-react-parser';
const ReportedPost = () => {
  const dispatch = useDispatch();


  const [users, setUsers] = useState({});
  const [usersdata, setusersdata] = useState(null);
  const [page, setPage] = useState(1);
  const [opencaleder, setopencaleder] = useState(false);
  const [fromdate, setFromdate] = useState(addDays(new Date(), -15));
  const [todate, setTodate] = useState(new Date());
  const [srname, setsrname] = useState('');
  const [srcontent, setsrcontent] = useState('');
  const [open, setopen] = useState(false);
  const [reporteduser, setreporteduser] = useState(null);
  const countPerPage = 10;
  const [state, setState] = useState({
    startDate: addDays(new Date(), -15),
    endDate: new Date(),
    key: 'selection',
  })
  const getUserList = () => {
    // var token = localStorage.getItem("admintoken")
    var data = {
      "page": page,
      "limit": countPerPage,
      "name": srname,
      "content": srcontent,
      "from_date": fromdate ? moment(fromdate).format("YYYY-MM-DD") : '',
      "to_date": todate ? moment(todate).format("YYYY-MM-DD") : '',
      "device_type": role
    }
    dispatch(ReportedSearchPostAPI(data))

  };

  const postRes = useSelector(state => state.globalReducer.ReportedSearchPostRes);
  const columns = [
    {
      name: "Profile",
      selector: (row) => row.user_data.photo,
      ignoreRowClick: true,
      grow: 1,
      cell: (row) =>
        row.user_data ? (
          <Img
            className="rounded-circle img-fluid avatar-40 mr-3"
            src={row.user_data.photo}
            loader={<img src={user09} className="rounded-circle img-fluid avatar-40" />}
            unloader={<img src={user09} className="rounded-circle img-fluid avatar-40" />}
          />
        ) : (
          ""
        )
    },
    {
      name: 'Name',
      ignoreRowClick: true,
      selector: (row) => row.user_data.name,
      grow: 2,
      cell: (row) => <p style={{ padding: 5, margin: 0 }}>{row.user_data.name}</p>
    },
    {
      name: 'Content',
      ignoreRowClick: true,
      selector: (row) => row.content,
      grow: 3,
      cell: (row) => <p style={{padding: 5 ,margin: 0 }}>{parse(row.content)} {row.is_anonymous=="1"&&<><br/> <sapn style={{color: '#007bff',lineHeight:"2"}}>"Anonymously Posted"</sapn></>}</p>
    },
    {
      name: 'Date',
      ignoreRowClick: true,
      selector: (row) => row.created_at,
      grow: 2,
      cell: (row) => <p style={{ padding: 5, margin: 0 }}>{moment(row.created_at).format('MMMM DD, YYYY')}</p>
    },
    {
      name: '',
      ignoreRowClick: true,
      selector: (row) => row._id,
      cell: (row) =><> <a target="_blank" href={`/postdetail/${row._id}`} data-tip data-for="postdetail"  style={{ padding: 5, margin: 0 }}>
        <i style={{ fontSize: 16 }} className="ri-share-box-line "></i></a>
       <ReactTooltip id="postdetail" type="dark">
            <span>View Post Details</span>
          </ReactTooltip></>
    },
    {
      name: '',
      ignoreRowClick: true,
      selector: (row) => row._id,
      cell: (row) => <><a className="cursor-point reported-user" data-tip data-for="reportedpost"  onClick={() => { debugger; setopen(true); setreporteduser(row.reported_by); }}> 
        <i class="ri-file-line"></i></a>
           <ReactTooltip id="reportedpost" type="dark">
           <span>Reported User List</span>
         </ReactTooltip></>
    },
    {
      name: "Action",
      grow: 1,
      selector: (row) => row.status,
      ignoreRowClick: true,
      cell: (row, index) =>
        row.status == 1 ?
        <label className="active-label cursor-point" onClick={() => activeclick(row)} >Active</label>
          : <label className="inactive-label cursor-point" onClick={() => activeclick(row)}>In Active</label>
    }
  ];

  function activeclick(i) {
    debugger
    swal({
      title: "",
      text: i.status == 1 ? "Are you sure you want to inactive this post?" : "Are you sure you want to active this post?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          debugger
          dispatch(PoststatusAPI({
            "id": i._id,
            "status": i.status == 1 ? 0 : 1,
            "device_type": role
          }));
          var list = []
          users && users.data.filter(j => {
            if (j._id == i._id) {
              j.status = j.status == 1 ? 0 : 1
            }
            list.push(j)
          })
          users.data = list;
          debugger
          setusersdata(users.data);
        }
      });

  }
  useEffect(() => {
    debugger
    // console.log('dfhjdsfj')
  }, [usersdata]);

  useEffect(() => {
    debugger
    if (postRes) {
      setUsers(postRes);
      setusersdata(postRes.data);
    }
  }, [postRes]);


  useEffect(() => {
    getUserList();
  }, [page]);



  function handleSelect(item) {
    debugger
    setState(item.selection)
    // setopencaleder(false)
  }

  function callsearch() {
    debugger
    setopencaleder(false);
    if (document.getElementById("pagination-first-page")) {
      if (document.getElementById("pagination-first-page").disabled) {
        getUserList();
      } else {
        document.getElementById("pagination-first-page").click();
      }
    } else {
      getUserList();
    }

  }
  debugger
  return (
    <>
      <Loader />
      <div className="wrapper">
        <Navbar page="reported" />

        <div id="content-page" className="content-page">
          <div className="container">
            <div className="row mb-4 ">
              <div className="col-lg-3 col-md-3">
                <input placeholder="Search by User" className="form-control search-inp mob-search-inp" value={srname} onChange={(e) => setsrname(e.target.value && e.target.value.trim() == "" ? "" : e.target.value)} maxLength={30} /></div>
              <div className="col-lg-3 col-md-3"><input className="form-control search-inp mob-search-inp " placeholder="Search by Content" value={srcontent} onChange={(e) => setsrcontent(e.target.value && e.target.value.trim() == "" ? "" : e.target.value)} maxLength={500} />
              </div>
              <div className="col-lg-3 col-md-4">
                <div className="rdrDateDisplayWrapper ">
                  <div className="rdrDateDisplay m-0">
                    <div className=" rdrDateInput rdrDateDisplayItem date-input-span" onClick={() => setopencaleder(!opencaleder)}>
                      <input readOnly={true} className="form-control search-inp " placeholder="Early" value={fromdate && todate ? moment(fromdate).format("MMM DD, YYYY") + " - " + moment(todate).format("MMM DD, YYYY") : "MM DD, YYYY - MM DD, YYYY"} />
                      <span className="date-input-icon input-icon-rights cursor-point"> <i className="fa fa-calendar-o"></i></span></div>
                    {/* <span className="rdrDateInput rdrDateDisplayItem">
                            <input readonly="" className="form-control search-inp" placeholder="Continuous" value={moment(todate).format("MMM DD, YYYY")}/></span> */}
                  </div></div>


                {opencaleder && <>
                  <div className="date-pick-div"><DateRangePicker
                    onChange={item => handleSelect(item)}
                    showSelectionPreview={false}
                    moveRangeOnFirstSelection={false}
                    ranges={[state]}
                    direction="horizontal"
                    maxDate={new Date()}
                    className={"date-range custom-dp"}
                  />
                    <div className="apply-date-btn-sec">
                      <button className="btn apply-date-btn clear-date-btn mr-3" onClick={() => { setopencaleder(false); setFromdate(''); setTodate('') }}>Clear</button>
                      <button className="btn apply-date-btn btn-primary mr-3" onClick={() => { setopencaleder(false); setFromdate(state.startDate); setTodate(state.endDate) }}>Apply</button>
                    </div>
                  </div>

                </>}
              </div>
              <div className="col-lg-3 col-md-2 col-sm-12"><button style={{ marginTop: 5 }} className="btn btn-primary  mob-search-btn" onClick={() => callsearch()}>Search</button></div>
            </div>
            <div className="row mb-4">
              <div className="col-md-12">
                {usersdata ? usersdata.length > 0 ?
                  <DataTable
                    className="search-datatb"
                    title="Reported Posts"
                    columns={columns}
                    data={usersdata}
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationResetDefaultPage={true}
                    paginationTotalRows={users.count}
                    paginationPerPage={countPerPage}
                    paginationComponentOptions={{
                      noRowsPerPage: true
                    }}
                    onChangePage={page => setPage(page)}
                  />
                  :
                  <div>
                    <div className="iq-card" >
                      <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title">
                          <h4 className="card-title">Posts</h4>
                        </div></div>
                      <p style={{ textAlign: 'center', padding: 10 }}>No Data Found</p>
                    </div>
                  </div>
                  :
                  <div className="col-sm-12 text-center" style={{ marginBottom: 50 }}>
                    <img src={pageloadloader} style={{ height: 80 }} />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <Modal
          classNames={{
            overlay: "customOverlay",
            modal: "customModal"
          }}
          open={open}
          center
          onClose={() => { }}
          showCloseIcon={false}
        >
          {open &&
            <div className="modal fade show create-post liked-by-user" id="post-modal" tabIndex="-1" role="dialog" aria-labelledby="post-modalLabel" aria-modal="true" style={{ paddingRight: '8', display: 'block' }}>
              <div className="modal-dialog reported-user-modal" role="document">
                <div className="modal-content">
                  <div className="modal-header">

                    <h5 className="modal-title" id="post-modalLabel">Reported User</h5>


                    <div className="custom-modal-close"><button type="button" className="btn  close-modal" data-dismiss="modal" onClick={() => { setopen(false); setreporteduser(null) }}><i className="ri-close-fill"></i></button></div>
                  </div>
                  <div className="modal-body">

                   {reporteduser &&
                        <table className="table table bordered report-table">
                          <thead>
                            <th>Profile</th>
                            <th>Name</th>
                            <th>Reason</th>
                            <th>Date</th>                            
                          </thead>
                          <tbody className="tbody-scrollable">
                          {reporteduser.length>0 ? reporteduser.map
                      (i => <tr>
                            <td><img className="rounded-circle img-fluid avatar-40 mr-3" src={i.photo?i.photo:user09} /></td>
                            <td><p>{i.name}</p></td>
                            <td> <p>{i.reason}</p></td>
                            <td><p>{moment(i.created_at).format('MMMM DD, YYYY')}</p></td>
                          </tr>    ):
                          <tr>
                            <td colSpan='4'><p style={{textAlign:'center',padding:10}}>No Data Found</p></td>
                            </tr>}
                          </tbody>
                        </table>}
                  
                  </div>
                </div>
              </div></div>
          }
        </Modal>
        {open && <div className="modal-backdrop fade show"></div>}
      </div>
      <Footer />
    </>
  )
}
export default ReportedPost