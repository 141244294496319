import { IS_LOADING } from "../constants";

let defaultState = {
  loading: false
  
};

const LoadingStatusReducer = (state = defaultState, action) => {
  
  switch (action.type) {
    case IS_LOADING:
      return Object.assign({}, state, {
        loading: action.payload
      });
    default:
      return state;
  }
};
export default LoadingStatusReducer;
