import { combineReducers } from "redux";
import LoadingStatusReducer from "./LoadingStatusReducer";
import globalReducer from "./globalReducer";

const reducersObj = {
    LoadingStatusReducer: LoadingStatusReducer,
    globalReducer:globalReducer,
};

const reducers = combineReducers(reducersObj);

export default reducers;
