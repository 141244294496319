import React, { Component } from "react";
import { connect } from "react-redux";
class Loader extends Component {
  render() {
    debugger
    const { loading = false } = this.props.loading;
    return (
      loading&&  <div id="loading">
      <div id="loading-center">
        
      </div>
   </div>
    );
  }
}



const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.LoadingStatusReducer.loading
  };
};

export default connect(mapStateToProps)(Loader);
