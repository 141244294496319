import React from "react";
import { connect } from "react-redux";
import {devicetype} from "../constants";
import { LoginAPI } from "../actions/globalactions";
import { dispatchLoadingStatus } from '../utils';
import Loader from "./Loader";

var CryptoJS = require("crypto-js");

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Email: '',
      Password: '',
      hidden: false,
      checked:false
    }
    this.handleChange = this.handleChange.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  componentDidMount() {
    var admintoken = localStorage.getItem("admintoken")
    if (!admintoken) {
      var email = localStorage.getItem("AdminEmail")
      var pass = localStorage.getItem("AdminPass")
      var bytes  = pass&&CryptoJS.AES.decrypt(pass, 'secret key 123');
    var originalText = bytes&&bytes.toString(CryptoJS.enc.Utf8);
      var Rememberme = localStorage.getItem("Rememberme")
      debugger
      this.setState({Email:email?email:"", Password:originalText?originalText:"",checked:Rememberme=="true"?true:false});
    }else{
      this.props.history.push(`/dashboard`);
    }
  }

  handleChange(event){
    debugger
    if(event.target.id=='Password' && event.target.value!=""){
      debugger
      this.setState({
        [event.target.id]: event.target.value.trim(),
        EError: "",
        PError: "",
      });
    }else{
      this.setState({
        [event.target.id]: event.target.value,
        EError: "",
        PError: "",
      });
    }
    
  }

  EnterPressCall = (e) => {
    if (e.charCode == 13 || e.which == 13) {
      this.login()
    }
  }

  login=()=> {
    debugger
    var mailFormat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,3})$/;
    if ( this.state.Email.trim()=="" || !this.state.Email) {
      this.setState({ EError: "Please enter Email address." });
    } else if (this.state.Email && !mailFormat.test(this.state.Email)) {
      this.setState({ EError: "Please provide a valid Email address." });
      return false;
    } else if ( this.state.Password.trim()=="" ||  !this.state.Password) {
      this.setState({ PError: "Please enter Password." });
    } else if (this.state.Password.length<8) {
      this.setState({ PError: "Password must be between 8 and 15." });
    } else {
      this.props.isLoading(true);
      this.APICall()
    }
}

APICall=()=>{
  const postdata = {
    "email": this.state.Email,
    "password":this.state.Password,
    "fcm_token": "",
"device_type":devicetype,
"is_admin" : 1
  }
  console.log(postdata);
    this.props.LoginAPI(postdata,this.state.checked);  //status of check
}

componentWillReceiveProps(nextprops) {
  debugger
  if (nextprops.loginRes !== null) {
    if (nextprops.loginRes !== this.props.loginRes) {
      debugger
      this.props.history.push(`/dashboard`);
    }
  }
}
    render() {
      debugger
      return (  <>
        <Loader />
        {/* <label htmlhtmlFor="exampleInputEmail1">Email </label>
          <input type="text" className="form-control mb-0" id="Email" placeholder="enter email " value={this.state.Email} onChange={this.handleChange} maxLength={80}/>
                                    {this.state.EError && <p style={{ color: 'red', fontSize: 14, float: 'left' }}>{this.state.EError}</p>}
                                   <br/> <label htmlhtmlFor="exampleInputPassword1">Password</label>
                                    <input type='text'autoComplete="off" className={!this.state.hidden?"form-control mb-0 passwordtext":"form-control mb-0"} id="Password" placeholder="enter password" value={this.state.Password} onChange={this.handleChange} maxLength={16}/>
                                    
                                    {this.state.hidden && <span className="input-icon-addon input-icon-rights" onClick={this.toggleShow}> <i className="fa fa-eye"></i> </span> }
                                    {!this.state.hidden && <span className="input-icon-addon input-icon-rights" onClick={this.toggleShow}> <i className="fa fa-eye-slash"></i> </span> }

                                    {this.state.PError && <p style={{ color: 'red', fontSize: 14, float: 'left' }}>{this.state.PError}</p>}
                                    <button type="submit" className="btn btn-primary float-right"  onClick={() => this.login()}>Sign in</button>   */}
                                    
                                    <section className="sign-in-page bg-white login" >
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 align-self-center">
                      <div className="sign-in-main" >
                        <div className="sign-in-from">
                          <a className="sign-in-logo text-center mb-2" href="#"><img src="images/logo.png" className="img-fluid" alt="logo"/></a>
                            <h1 className="mb-0">Sign in</h1>
                            
                            <div className=" row mt-4">
                              <div className="col-md-12">
                              <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Email address</label>
                                    <input type="text" className="form-control mb-0" autoFocus id="Email" placeholder="enter email " value={this.state.Email} onChange={this.handleChange} maxLength={80} onKeyPress={(e) => this.EnterPressCall(e)}/>
                                    {this.state.EError && <p style={{ color: 'red', fontSize: 14, float: 'left' }}>{this.state.EError}</p>}
                                </div>
                                </div>                               
                                <div className="col-md-12">
                                <div className="form-group addon-input ">
                                    <label htmlFor="exampleInputPassword1">Password</label>
                                   
                                    {/* <input type="password" className="form-control mb-0" id="exampleInputPassword1" placeholder="Password"/> */}
                                    <input type='text'autoComplete="off" className={!this.state.hidden?"form-control mb-0 passwordtext":"form-control mb-0"} id="Password" placeholder="enter password" value={this.state.Password} onChange={this.handleChange} maxLength={16} onKeyPress={(e) => this.EnterPressCall(e)}/>
                                  
                                    {this.state.hidden && <span className="input-icon-addon input-icon-rights cursor-point" onClick={this.toggleShow}> <i className="fa fa-eye"></i> </span> }
                                    {!this.state.hidden && <span className="input-icon-addon input-icon-rights cursor-point" onClick={this.toggleShow}> <i className="fa fa-eye-slash"></i> </span> }
                                  
                                    {this.state.PError && <p style={{ color: 'red', fontSize: 14, float: 'left' }}>{this.state.PError}</p>}
                                    <a href="/forgotpassword" className="float-right mb-3 pt-1">Forgot password?</a>
                                </div>
                                </div>
                                <div className="col-md-12">
                                <div className="d-inline-block w-100">
                                    <div className="custom-control custom-checkbox d-inline-block mt-2 pt-1">
                                        <input type="checkbox" className="custom-control-input" id="customCheck1" onClick={() => this.setState({checked:!this.state.checked})} checked={this.state.checked} />
                                        <label className="custom-control-label" htmlFor="customCheck1">Remember Me</label>
                                    </div>
                                    <button type="" className="btn btn-primary float-right" onClick={() => this.login()}>Sign In</button>
                                </div>
                                </div>
                                {/* <div className="sign-info">
                                    <span className="dark-color d-inline-block line-height-2">Don't have an account? <a href="#">Sign up</a></span>
                                    
                                </div> */}
                            </div>
                        </div>
                      </div>
                    </div>
                  
                </div>
            </div>
        </section>
        
                                    
                                     </> )
    }
  }
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
                isLoading: loading => {
                dispatch(dispatchLoadingStatus(loading));
      },
      LoginAPI:(postdata,Rememberme)=>{dispatch(LoginAPI(postdata,Rememberme))}
    }
  }
  const mapStateToProps = (state, ownProps) => {
    return {
                loading: state.LoadingStatusReducer.loading,
                loginReq:state.globalReducer.LoginReq,
      loginRes:state.globalReducer.LoginRes
    }
  }
  export default connect(mapStateToProps, mapDispatchToProps)((Login))
