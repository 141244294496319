import React from "react";
import logo from '../asset/images/logo.png';
import admin1 from '../asset/images/admin1.jpg';
import swal from 'sweetalert';

export default class Navbar extends React.Component {


logout=()=>{
   swal({
      title: "",
      text: "Are you sure you want to logout?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
   })
      .then((willDelete) => {
         if (willDelete) {
           this.finallogout()
         } else {
         }
      });
  
}

 finallogout=()=>{
   localStorage.removeItem("admintoken")
      window.location.href = window.location.origin+`/login`;
}

// Management
    render() {
        return (  <>
         <div className="iq-top-navbar">
            <div className="container-fluid">
                        <div className="row">
                           <div className="col-sm-12">
            <div className="iq-navbar-custom d-flex align-items-center justify-content-between">
               <div className="iq-sidebar-logo">
                  <div className="">
                     <a href={window.location.href.includes('dashboard')?'#':'/dashboard'} className="logo">
                     <img src={logo} className="img-fluid" alt=""/>
                  
                     </a>
                  </div>
               </div>
               <div className="iq-menu-horizontal">
                  <nav className="iq-sidebar-menu">                     
                  <ul id="iq-sidebar-toggle" className="iq-menu d-flex">
                     <li className={this.props.page=="dashboard"?"active":""}>
                        <a href={window.location.href.includes('dashboard')?'#':'/dashboard'} className="iq-waves-effect "><i className="ri-home-4-line"></i><span>Dashboard</span></a>
                       
                     </li>
                    
                     <li className={this.props.page=="user" || this.props.page=="details"?"active":""}>
                        <a href={window.location.href.includes('user')?'#':'/user'} className="iq-waves-effect "  ><i className="ri-user-line"></i><span>User</span></a>
                     </li>
                     <li className={this.props.page=="content"?"active":""}>
                        <a href={window.location.href.includes('content')?'#':'/content'} className="iq-waves-effect "><i className="ri-pencil-ruler-line"></i><span>Content</span></a>
                     </li>
                     <li className={this.props.page=="emotion"?"active":""}>
                        <a href={window.location.href.includes('emotion')?'#':'/emotion'} className="iq-waves-effect "><i className="ri-emotion-happy-line"></i><span>Emotion</span></a>
                     </li>
                     <li className={this.props.page=="search-post"?"active":""}>
                     <a href={window.location.href.includes('search-post')?'#':'/search-post'} className="iq-waves-effect "><i className="ri-search-line"></i><span>Search Post</span></a>
                     </li>
                     <li className={this.props.page=="reported"?"active":""}>
                     <a href={window.location.href.includes('reported')?'#':'/reported'} className="iq-waves-effect "><i className="ri-error-warning-line"></i><span>Reported Post</span></a>
                     </li>
                     <li className={this.props.page=="feedback"?"active":""}>
                     <a href={window.location.href.includes('feedback')?'#':'/feedback'} className="iq-waves-effect "><i className="ri-feedback-line"></i><span>Feedbacks</span></a>
                     </li>
                    </ul>
               </nav>
               </div>
               <nav className="navbar navbar-expand-lg navbar-light p-0">
            
                  <div className="iq-menu-bt align-self-center">
                     <div className="wrapper-menu">
                        <div className="line-menu half start"></div>
                        <div className="line-menu"></div>
                        <div className="line-menu half end"></div>
                     </div>
                  </div>
              
                  <ul className="navbar-list">
                     <li className="">
                     {/* <a className=" admin-name-deskt iq-waves-effect d-flex align-items-center">
                                 
                                 <img src={admin1} className="img-fluid admin-profile rounded" alt="user"/>
                                 
                                 
                                                                        <div className="caption">
                                                                           <h6 className="mb-0 line-height">
                                                                           {localStorage.getItem("AName")?localStorage.getItem("AName"):"Admin"}
                                                                           </h6>
                                                                           <span className=" font-size-12" style={{color: '#9a9a9a'}}>
                                                                           {localStorage.getItem("AEmail")?localStorage.getItem("AEmail"):"Email"}
                                                                           </span>
                                                                        </div>
                                                                     <div  role="button" ><i onClick={()=>this.logout()} className="ri-login-box-line ml-2 cursor-point"></i></div>
                                                                  </a> */}

                        <a href="#" className=" admin-name-mob search-toggle iq-waves-effect "><img src={admin1} className="admin-profile img-fluid rounded" alt="user"/>
                        <i className="ri-arrow-down-s-fill ml-2 profile-arrow"></i></a>
                        <div className="iq-sub-dropdown iq-user-dropdown">
                           <div className="iq-card shadow-none m-0">
                              <div className="iq-card-body p-0 ">
                                 <div className="bg-primary p-3">
                                    <h5 className="mb-0 text-white line-height">{localStorage.getItem("AName")?localStorage.getItem("AName"):"Admin"}</h5>
                                    
                                 </div>
                                 <a className="iq-sub-card iq-bg-primary-hover">
                                    <div className="media align-items-center">
                                       <div className="rounded iq-card-icon iq-bg-primary">
                                          <i className="ri-mail-line"></i>
                                       </div>
                                       <div className="media-body ml-3">
                                          <h6 className="mb-0 ">{localStorage.getItem("AEmail")?localStorage.getItem("AEmail"):"Email"}</h6>
                                         </div>
                                    </div>
                                 </a>
                              
                                
                                 
                                 <div className="d-inline-block w-100 text-center p-3">
                                    <a className="iq-bg-danger iq-sign-btn cursor-point"  onClick={()=>this.logout()} role="button">Log out<i className="ri-login-box-line ml-2"></i></a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </li>
                 
                 {/* <li className="profile-li">
                                 <a className="search-toggle iq-waves-effect d-flex align-items-center">
                                 
<img src={admin1} className="img-fluid admin-profile rounded" alt="user"/>


                                    <div className="caption">
                                       <h6 className="mb-0 line-height">
                                       {localStorage.getItem("AName")?localStorage.getItem("AName"):"Admin"}
                                       </h6>
                                       <span className=" font-size-12" style={{color: '#9a9a9a'}}>
                                       {localStorage.getItem("AEmail")?localStorage.getItem("AEmail"):"Email"}
                                       </span>
                                    </div>
                                    <a  role="button" ><i onClick={()=>this.logout()} className="ri-login-box-line ml-2 cursor-point"></i></a>
                                 </a>
                                 </li> */}
                 
                  </ul>
               </nav>
            </div>
         </div>
      </div>
   </div>
         </div>
         
         </>
      )
  }
}