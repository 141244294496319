
export const devicetype = "web";
export const role = "admin";

// --------// Local ----------
// export const baseURL = "https://dev-api.cizzr.com/api/"; 
// export const weburl = "http://localhost:3001";
// export const currentorigin= "http://localhost:3000";
// export const crossorigin = "http://localhost:3001";

// --------// QA ----------
// export const baseURL = "https://dev-api.cizzr.com/api/"; 
// export const weburl = "https://webqa.cizzr.com";
// export const currentorigin = "https://adminqa.cizzr.com";
// export const crossorigin = "https://webqa.cizzr.com";

// --------// Live ----------
export const baseURL = "https://api.cizzr.com/api/";
export const weburl = "https://cizzr.com";
export const currentorigin = "https://admin.cizzr.com";
export const crossorigin = "https://cizzr.com";
export const crossweborigin = "https://web.cizzr.com";


export const IS_LOADING = "IS_LOADING";
export const tokenexpire = "Your token has expired! Please sign in again."
export const errormsg = "Something went wrong please try again."

export const Login_URL = "login";
export const LoginReq = "LoginReq";
export const LoginRes = "LoginRes";

export const Userlist_URL = "/admin/get-user-list";
export const UserRes = "UserRes";

export const Userstatus_URL = "/admin/change-user-status";
export const UserstatusRes = "UserstatusRes";

export const Dashboard_URL = "/admin/dashboard";
export const DashboardRes = "DashboardRes";

export const AddContent_URL = "/admin/add-page-data";

export const ForgotPass_URL = "forgot-password";
export const ForgotPass_Verify_URL = "forgot-password-verify-otp";
export const ResetPass_URL = "set-password";


export const PageDetails_URL = "/admin/page-details";
export const PageDetailsRes = "PageDetailsRes";

export const Emoptionlist_URL = "/admin/emotion/list";
export const EmoptionRes = "EmoptionRes";

export const EmotionAddUpdate_URL = "/admin/emotion/add-update";
export const EmotionAddUpdateRes = "EmotionAddUpdateRes";

export const Emotionstatus_URL = "/admin/emotion/status-update";
export const EmotionOrderUpdate_URL = "/admin/emotion/order-update";

export const SearchPost_URL = "/admin/post/list";
export const SearchPostRes = "SearchPostRes";

export const ReportedSearchPost_URL = "/admin/post/list-reported";
export const ReportedSearchPostRes = "ReportedSearchPostRes";

export const Poststatus_URL = "/admin/post/status-update";
export const PoststatusRes = "PoststatusRes";

export const Feedbackist_URL = "/admin/feedback_display";
export const FeedbackRes = "FeedbackRes";

export const UserPostlist_URL = "/admin/get-post-list-by-userid";
export const UserPostRes = "UserPostRes";

export const PostDetails_URL = "/post/detail";
export const PostDetailRes = "PostDetailRes";

export const UPVoteList_URL = "/post/up-vote-list";
export const UPVoteListRes= "UPVoteListRes";

export const DownVoteList_URL = "/post/down-vote-list";
export const DownVoteListRes = "DownVoteListRes";