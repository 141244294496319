

import React from "react";
import moment from 'moment';
export default class Footer extends React.Component {
    render() {
        return (  
<footer className="bg-white iq-footer">
<div className="container-fluid">
   <div className="row">
     <div className="col-lg-6">
         {/* // <ul className="list-inline mb-0">
         //    <li className="list-inline-item"><a href="">Privacy Policy</a></li>
         //    <li className="list-inline-item"><a href="">Terms of Use</a></li>
         // </ul> */}
      </div> 
      <div className="col-lg-6 text-right">
      Copyright © {moment().year()} <a href={window.location.href.includes('dashboard')?'#':'/dashboard'}>Cizzr.</a> All Rights Reserved.
      </div>
   </div>
</div>
</footer>
        )}
    }