import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { FeedbackistAPI } from "../actions/globalactions";
import Navbar from './Navbar';
import Loader from './Loader';
import pageloadloader from "../asset/images/page-load-loader.gif";
import { role } from "../constants";
import moment from "moment";
import Footer from './Footer';

const Feedback = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [feedbackRes, setfeedbackRes] = useState(null);
    const feedbackResapi = useSelector(state => state.globalReducer.FeedbackRes);

    const [page, setPage] = useState(1);
    const countPerPage = 10;
    const [fdbkcount, setfdbkcount] = useState(0);

    const columns = [{
        name: "Name",
        selector: (row) => row.name,
        grow: 2,
        ignoreRowClick: true,
        cell: (row) => <p className="user-Name">{row.name}</p>
    },
    {
        name: "Email",
        selector: (row) => row.email,
        grow: 2,
        ignoreRowClick: true,
        cell: (row) => row.email ? <p className="user-Name">{row.email}</p> : <p className="user-Name">{"-"}</p>
    },
    {
        name: "Contact No.",
        selector: (row) => row.phone_number,
        ignoreRowClick: true,
        grow: 1,
        cell: (row) => row.phone_number ?<p className="user-Name">{row.phone_number.toString().includes('+')?row.phone_number:'+'+row.phone_number}</p>:<p className="user-Name">{"-"}</p>
    },
    {
        name: 'Message',
        ignoreRowClick: true,
        selector: (row) => row.feedback,
        grow:3,
        cell: (row) =><p style={{padding: 5 ,margin: 0 }}>{row.feedback} </p>
      }
    ];

    useEffect(() => {
        debugger
        var admintoken = localStorage.getItem("admintoken")
        if (!admintoken) {
            history.push(`/login`);
        }
    }, []);

    const callfeedbacklist = () => {
        var data = {
            "page": page,
            "limit": countPerPage,
            "device_type": role
        }
        dispatch(FeedbackistAPI(data))
    };

    useEffect(() => {
        callfeedbacklist();
    }, [page]);

    useEffect(() => {
        debugger
        if (feedbackResapi != null) {
            var data = feedbackResapi.data;
            data.map((i, index) => i.srno = index + 1)
            setfeedbackRes(feedbackResapi.data);
            setfdbkcount(feedbackResapi.count);
        }
    }, [feedbackResapi]);

    return (<>
        <Loader />
        <div className="wrapper">
            <Navbar page="feedback" />
            <div id="content-page" className="content-page">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="iq-card">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title">Feedback List</h4>
                                    </div>
                                </div>
                                <div className="iq-card-body">
                                    <div className="table-responsive">

                                        {feedbackRes ? feedbackRes.length > 0 ?
                                            <DataTable
                                                fixedHeader={true}
                                                columns={columns}
                                                data={feedbackRes}
                                                pagination
                                                highlightOnHover
                                                paginationServer
                                                paginationResetDefaultPage={true}
                                                paginationTotalRows={fdbkcount}
                                                paginationPerPage={countPerPage}
                                                paginationComponentOptions={{
                                                    noRowsPerPage: true
                                                }}
                                                onChangePage={page => setPage(page)}
                                            />
                                            : <p style={{ textAlign: 'center', padding: 10 }}>No Data Found</p> : <div className="col-sm-12 text-center" style={{ marginBottom: 50 }}>
                                            <img src={pageloadloader} style={{ height: 80 }} />
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>
    )
}

export default Feedback