import React from "react";
import { connect } from "react-redux";
import { ResetPassAPI } from "../actions/globalactions";
import { dispatchLoadingStatus } from '../utils';
import {devicetype} from "../constants";
import Loader from "./Loader";

class ResetPW extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        Password: '',
        confirmPassword: '',
        hidden: false,
        hidden1: false,
      }
      this.handleChange = this.handleChange.bind(this);
      this.togglePass = this.togglePass.bind(this);
      this.toggleCpass = this.toggleCpass.bind(this);
    }
    
    togglePass() {
      this.setState({ hidden: !this.state.hidden });
    }
    toggleCpass() {
      this.setState({ hidden1: !this.state.hidden1 });
    }
    componentDidMount(){
      var admintoken = localStorage.getItem("admintoken")
      var femail = localStorage.getItem("AFemail")
debugger
if(femail && !admintoken){
}else{
  this.props.history.push(`/dashboard`);
}
   }
    handleChange(event){
      debugger
        this.setState({
          [event.target.id]:event.target.value.trim(),
        PError: "",
        CPError: "",
      });
    }

    Validatepassword=(password)=> {
      var upper = /[A-Z]/,
          lower = /[a-z]/,
          number = /[0-9]/,
          special = /[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/;
    
      if (password.length>7 &&
          upper.test(password) &&
          lower.test(password) &&
          number.test(password) &&
          special.test(password)
      ) {
          return true;
      }
    
      return false;
    }



    resetpass() {
        debugger
        
    var passFormat = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9])(?=.*[a-z]).{8,15}$/;
        if ( this.state.Password.trim()=="" || !this.state.Password) {
          this.setState({ PError: "Please enter New Password." });
        } 
        else if (!this.Validatepassword(this.state.Password)) {
          this.setState({ PassError: true });
        }
        // else if (this.state.Password.length<8) {
        //     this.setState({ PError: "Password must be between 8 to 15." });
        //   }  
        //   else if (!passFormat.test(this.state.Password)) {
        //     this.setState({ PError: "Password must be between 8 to 15 characters and it contains at least 1 letter in Uppercase, 1 letter in Lowercase, 1 Special Character (!@#$&*) and 1 Number." });
        //   }
          else if ( this.state.confirmPassword.trim()=="" ||  !this.state.confirmPassword) {
          this.setState({ CPError: "Please enter Confirm New Password." });
        }  else if (this.state.Password !== this.state.confirmPassword) {
            this.setState({ CPError: "Password and Confirm Password does not match." });
          }else {
            //   alert("call api..")
          this.APICall()
        }
    }



    APICall(){
      const postdata = {
        "email": localStorage.getItem("AFemail"),
        "password":this.state.Password,
        "device_type": devicetype,
        "is_admin" : 1
      }
      this.props.isLoading(true);
        this.props.ResetPassAPI(postdata);  //status of check
    }
    validatepass = (passw) => {
      debugger
      // var minMaxLength = /^[\s\S]{8,15}$/,
      var upper = /[A-Z]/,
        lower = /[a-z]/,
        number = /[0-9]/,
        special = /[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/;
  
      this.setState({
        PError:"",
        PassError:true,
        Password: passw != ""?passw.trim():"",
        isminmax: passw.length>7 ? true : false,
        isupper: upper.test(passw) ? true : false,
        islower: lower.test(passw) ? true : false,
        isnumber: number.test(passw) ? true : false,
        isspecial: special.test(passw) ? true : false
      })
    }
    EnterPressCall = (e) => {
      if (e.charCode == 13 || e.which == 13) {
        this.resetpass()
      }
    }

    render() {
      return ( 
        <>
        <Loader />
         <section className="sign-in-page bg-white login" >
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 align-self-center">
                      <div className="sign-in-main" >
                        <div className="sign-in-from">
                          <a className="sign-in-logo text-center mb-2" href="#"><img src="images/logo.png" className="img-fluid" alt="logo"/></a>
                              <h1 className="mb-0">Reset Password</h1>
                            <div className="mt-4 row ">
                              <div className="col-md-12">
                                <div className="form-group addon-input">
                                    <label htmlFor="exampleInputPassword1">New Password</label>
                                   
                                    <input type='text' autoSu autoComplete="off" className={!this.state.hidden ?"form-control mb-0 passwordtext":"form-control mb-0"} id="Password" placeholder="enter new password" value={this.state.Password} onChange={(e)=>this.validatepass(e.target.value)} maxLength={16} onKeyPress={(e) => this.EnterPressCall(e)}/>

                                    {this.state.hidden && <span className="input-icon-addon input-icon-rights cursor-point" onClick={this.togglePass}> <i className="fa fa-eye"></i> </span> }
                                    {!this.state.hidden && <span className="input-icon-addon input-icon-rights cursor-point" onClick={this.togglePass}> <i className="fa fa-eye-slash"></i> </span> }
                                    {this.state.PError && <p style={{ color: 'red', fontSize: 14, float: 'left' }}>{this.state.PError}</p>}
                                    {this.state.PassError && this.state.Password?
                        (!this.state.isminmax || !this.state.isupper || !this.state.islower || !this.state.isspecial || !this.state.isnumber)
                        ?
                          <><p className="error-msg-p" style={{ color: 'red', fontSize: 14, float: 'left'}}>Your password needs to:</p>
                            <p className="error-msg-p" style={{ color: this.state.isupper&&this.state.islower?'green':'red', fontSize: 12, float: 'left'}}>include both uppercase and lowercase characters.</p>
                            <p className="error-msg-p" style={{ color: this.state.isnumber?'green':'red', fontSize: 12, float: 'left'}}>include at least one number.</p>
                            <p className="error-msg-p" style={{ color: this.state.isspecial?'green':'red', fontSize: 12, float: 'left'}}>include at least one special character.</p>
                            <p className="error-msg-p" style={{ color: this.state.isminmax?'green':'red', fontSize: 12, float: 'left'}}>be at least 8 characters long.</p>
                            </>:null:null}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group addon-input">
                                <label htmlFor="exampleInputPassword1">Confirm New Password</label>
                                    {/* <input type='password' className="form-control mb-0" id="confirmPassword" placeholder="confirm password" value={this.state.confirmPassword} onChange={this.handleChange} maxLength={15}/> */}
                                    <input type='text' autoComplete="off" className={!this.state.hidden1 ?"form-control mb-0 passwordtext":"form-control mb-0"} id="confirmPassword" placeholder="confirm new password" value={this.state.confirmPassword} onChange={this.handleChange} maxLength={16} onKeyPress={(e) => this.EnterPressCall(e)}/>
                                    
                                    {this.state.hidden1 && <span className="input-icon-addon input-icon-rights cursor-point" onClick={this.toggleCpass}> <i className="fa fa-eye"></i> </span> }
                                    {!this.state.hidden1 && <span className="input-icon-addon input-icon-rights cursor-point" onClick={this.toggleCpass}> <i className="fa fa-eye-slash"></i> </span> }
                                    {this.state.CPError && <p style={{ color: 'red', fontSize: 14, float: 'left' }}>{this.state.CPError}</p>}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="d-inline-block w-100">
                                    <button type="submit" className="btn btn-primary float-right"  onClick={() => this.resetpass()}>Reset</button>
                                </div>
                              </div>
                            </div>
                            </div>
                      </div>
                    </div>
                  
                </div>
            </div>
        </section>
      </>
  );
}
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
                isLoading: loading => {
                dispatch(dispatchLoadingStatus(loading));
      },
      ResetPassAPI:(postdata)=>{dispatch(ResetPassAPI(postdata))}
    }
  }
  const mapStateToProps = (state, ownProps) => {
    return {
                loading: state.LoadingStatusReducer.loading,
      resetres:state.globalReducer.ResetPassRes
    }
  }
  export default connect(mapStateToProps, mapDispatchToProps)((ResetPW))
