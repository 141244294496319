import {
  LoginReq, LoginRes, UserRes, UserstatusRes, DashboardRes, PageDetailsRes, EmoptionRes,
  EmotionAddUpdateRes, SearchPostRes, PoststatusRes, ReportedSearchPostRes, FeedbackRes,UserPostRes,PostDetailRes,UPVoteListRes,DownVoteListRes
} from "../constants";

let defaultState = {
  LoginReq: null,
  LoginRes: null,
  UserRes: null,
  UserstatusRes: null,
  DashboardRes: null,
  PageDetailsRes: null,
  EmoptionRes: null,
  EmotionAddUpdateRes: null,
  SearchPostRes: null,
  PoststatusRes: null,
  ReportedSearchPostRes: null,
  FeedbackRes: null,
  UserPostRes:null,
  PostDetailRes:null,
  UPVoteListRes:null,
  DownVoteListRes:null
};

const globalReducer = (state = defaultState, action) => {

  switch (action.type) {

    case LoginReq:
      return Object.assign({}, state, {
        LoginReq: action.payload
      });
    case LoginRes:
      return Object.assign({}, state, {
        LoginRes: action.payload
      });

    case UserRes:
      return Object.assign({}, state, {
        UserRes: action.payload
      });
    case UserstatusRes:
      return Object.assign({}, state, {
        UserstatusRes: action.payload
      });
    case DashboardRes:
      return Object.assign({}, state, {
        DashboardRes: action.payload
      });
    case PageDetailsRes:
      return Object.assign({}, state, {
        PageDetailsRes: action.payload
      });
    case EmoptionRes:
      return Object.assign({}, state, {
        EmoptionRes: action.payload
      });
    case EmotionAddUpdateRes:
      return Object.assign({}, state, {
        EmotionAddUpdateRes: action.payload
      });
    case SearchPostRes:
      return Object.assign({}, state, {
        SearchPostRes: action.payload
      });
    case PoststatusRes:
      return Object.assign({}, state, {
        PoststatusRes: action.payload
      });
    case ReportedSearchPostRes:
      return Object.assign({}, state, {
        ReportedSearchPostRes: action.payload
      });
    case FeedbackRes:
      return Object.assign({}, state, {
        FeedbackRes: action.payload
      });
    case UserPostRes:
      return Object.assign({}, state, {
        UserPostRes: action.payload
      });
      case PostDetailRes:
        return Object.assign({}, state, {
          PostDetailRes: action.payload
        });
        case UPVoteListRes:
        return Object.assign({}, state, {
          UPVoteListRes: action.payload
        });
        case DownVoteListRes:
        return Object.assign({}, state, {
          DownVoteListRes: action.payload
        });
        
    default:
      return state;
  }
};
export default globalReducer;
