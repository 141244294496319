import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect
} from "react-router-dom";
import Login from './Components/Login';
import Forgot from './Components/forgotpass';
import OTP from './Components/otp';
import ResetPW from './Components/resetpassword';
import Dashboard from './Components/Dashboard';
import Usermanage from './Components/Usermanage';
import Contentmanage from './Components/Contentmanage';
import EmotionOrder from './Components/EmotionOrder';
import PostSearch from './Components/PostSearch';
import ReportedPost from './Components/ReportedPost';
import Feedback from './Components/Feedbackmanage';
import Userdetails from './Components/Userdetails';
import PostDetailAdmin from './Components/Postdetails';
// import {currentorigin,crossorigin} from './constants'
// var createGuest = require("cross-domain-storage/guest");
// var createHost = require("cross-domain-storage/host");

// var storageHost = createHost([
//   {
//     origin: currentorigin,
//     allowedMethods: ["get", "set", "remove"],
//   },
//   {
//     origin: crossorigin,
//     allowedMethods: ["get", "set", "remove"],
//   },
// ]);

class App extends React.Component {

  render() {
    return (
      <Router>
        <Route exact path="/" render={() => {return (<Redirect to={`/dashboard`} /> )}}/>
         <Route exact path={`/login`} component={Login} />
         <Route exact path={`/forgotpassword`} component={Forgot} />
         <Route exact path={`/otp`} component={OTP} />
         <Route exact path={`/resetpassword`} component={ResetPW} />
         <Route exact path={`/dashboard`} component={Dashboard} />
         <Route exact path={`/user`} component={Usermanage} />
         <Route exact path={`/content`} component={Contentmanage} />
         <Route exact path={`/emotion`} component={EmotionOrder} />
         <Route exact path={`/search-post`} component={PostSearch} />
         <Route exact path={`/reported`} component={ReportedPost} />
         <Route exact path={`/detail`} component={Userdetails} />
         <Route exact path={`/postdetail/:id`} component={PostDetailAdmin} />
         <Route exact path={`/feedback`} component={Feedback} />
        </Router>
    )
  }
}

export default App;
