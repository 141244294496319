import React from "react";
import { connect } from "react-redux";
import {devicetype} from "../constants";
import { ForgotPassAPI } from "../actions/globalactions";
import { dispatchLoadingStatus } from '../utils';
import Loader from "./Loader";


class Forgot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Email: ''
    }
    this.handleChange = this.handleChange.bind(this);
  }


  componentDidMount() {
    var admintoken = localStorage.getItem("admintoken")
    if (!admintoken) {
    }else{
      this.props.history.push(`/dashboard`);
    }
  }

  handleChange(event){
    debugger
      this.setState({
        [event.target.id]: event.target.value,
        Error: ""
      });
  }


componentWillReceiveProps(nextprops) {
  debugger
  if (nextprops.loginRes !== null) {
    if (nextprops.loginRes !== this.props.loginRes) {
      debugger
      this.props.history.push(`/dashboard`);
    }
  }
}

getOtp=()=> {
    var mailFormat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,3})$/;
    if (!this.state.Email) {
        this.setState({Error:"Please enter your Email address."})
    } else if (this.state.Email && !mailFormat.test(this.state.Email)) {
        this.setState({Error:"Please provide a valid Email address."})
        return false;
    } else {
        const postdata = {
            "email": this.state.Email,
            "device_type":devicetype,
            "is_admin" : 1
        }
        this.props.isLoading(true);
        this.props.ForgotPassAPI(postdata);
    }
}

EnterPressCall = (e) => {
  if (e.charCode == 13 || e.which == 13) {
    this.getOtp()
  }
}

    render() {
      debugger
      return (  <>
        <Loader />
         <section className="sign-in-page bg-white login" >
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 align-self-center">
                      <div className="sign-in-main" >
                        <div className="sign-in-from">
                          <a className="sign-in-logo text-center mb-2" href="#"><img src="images/logo.png" className="img-fluid" alt="logo"/></a>
                            <h1 className="mb-0">Forgot Password</h1>
                            
                            <div className="mt-4">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Email address</label>
                                    <input type="text" className="form-control mb-0" id="Email" placeholder="enter email " value={this.state.Email} onChange={this.handleChange} maxLength={80} onKeyPress={(e) => this.EnterPressCall(e)}/>
                                    {this.state.Error && <p style={{ color: 'red', fontSize: 14, float: 'left' }}>{this.state.Error}</p>}
                                </div>
                                <div className="d-inline-block w-100">
                                    <button type="" className="btn btn-primary float-right" onClick={() =>this.getOtp()}>Get OTP</button>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  
                </div>
            </div>
        </section>
        
                                    
                                     </> )
    }
  }
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
                isLoading: loading => {
                dispatch(dispatchLoadingStatus(loading));
      },
      ForgotPassAPI:(data)=>{dispatch(ForgotPassAPI(data))}
    }
  }
  const mapStateToProps = (state, ownProps) => {
    return {
                loading: state.LoadingStatusReducer.loading
    }
  }
  export default connect(mapStateToProps, mapDispatchToProps)((Forgot))
