import { getAsios, dispatchLoadingStatus,encryptStorage } from "../utils";
import {
    Login_URL, LoginRes, Userlist_URL, UserRes, Userstatus_URL, UserstatusRes, Dashboard_URL, DashboardRes, AddContent_URL
    , ForgotPass_URL, ForgotPass_Verify_URL, ResetPass_URL,PageDetails_URL,PageDetailsRes,Emoptionlist_URL,EmoptionRes,
    EmotionAddUpdate_URL,EmotionAddUpdateRes,Emotionstatus_URL,EmotionOrderUpdate_URL,SearchPost_URL,SearchPostRes
    ,Poststatus_URL,PoststatusRes, ReportedSearchPost_URL, ReportedSearchPostRes, Feedbackist_URL, FeedbackRes,
    UserPostlist_URL, UserPostRes,PostDetails_URL,PostDetailRes,UPVoteList_URL,UPVoteListRes,DownVoteList_URL,DownVoteListRes
} from "../constants";
import swal from 'sweetalert';

var CryptoJS = require("crypto-js");




export function LoginAPI(data, Rememberme) {
    return function (dispatch, getState) {
        return getAsios().post(Login_URL, data)
            .then((response) => {
                debugger
                if (response.data.success) {
                    debugger
                    if (Rememberme) {
                        debugger
                        localStorage.setItem("AdminEmail", data.email)
                        var ciphertext = data.password && CryptoJS.AES.encrypt(data.password, 'secret key 123').toString()
                        localStorage.setItem("AdminPass", ciphertext)
                    } else if (!Rememberme) {
                        localStorage.removeItem("AdminEmail")
                        localStorage.removeItem("AdminPass")
                    }
                    localStorage.setItem("Rememberme", Rememberme)
                    localStorage.setItem('admintoken', response.data.data.token_type + " " + response.data.data.access_token);
                    localStorage.setItem("AEmail", response.data.data.email)
                    localStorage.setItem("AName", response.data.data.name)
                    localStorage.setItem("Aid", response.data.data._id)
                    
                    localStorage.setItem("admin_web_data", JSON.stringify(response.data.admin_web_data))

                    dispatch({ type: LoginRes, payload: response.data });
                    return;
                } else {
                    swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }
                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                debugger
                if (err.toString().includes("401")) {
                    swal({text: "Your token has expired! Please sign in again.", icon: "error"}).then(function() {
                        localStorage.clear();
                    window.location.href = window.location.origin+`/login`;
                });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function ForgotPassAPI(data,resend) {
    debugger
    return function (dispatch, getState) {
        return getAsios().post(ForgotPass_URL, data)
            .then((response) => {
                debugger
                if (response.data.success) {
                    localStorage.setItem("Aforgot_pass", true);
                    localStorage.setItem("AFemail", data.email);
                    if(!resend){
                        window.location.href = window.location.origin + `/otp`;
                    }
                    // dispatch({ type: ForgotPassRes, payload: response.data });
                } else {
                    swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({text: "Your token has expired! Please sign in again.", icon: "error"}).then(function() {
                        localStorage.clear();
                    window.location.href = window.location.origin+`/login`;
                });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function ForgotPassVerifyAPI(data) {
    return function (dispatch, getState) {
        return getAsios().post(ForgotPass_Verify_URL, data)
            .then((response) => {
                debugger
                if (response.data.success) {
                    localStorage.setItem('forgot_pass', false);
                    window.location.href = window.location.origin + `/resetpassword`;
                } else {
                    swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }
                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({text: "Your token has expired! Please sign in again.", icon: "error"}).then(function() {
                        localStorage.clear();
                    window.location.href = window.location.origin+`/login`;
                });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function ResetPassAPI(data) {
    return function (dispatch, getState) {
        return getAsios().post(ResetPass_URL, data)
            .then((response) => {
                debugger
                if (response.data.success) {
                    // dispatch({ type: ResetPassRes, payload: response.data });
                    localStorage.removeItem("Aforgot_pass")
                    localStorage.removeItem("AFemail")
                    swal({ text: response.data.message, icon: "success" }).then(function () {
                        window.location.href = window.location.origin + `/login`;
                    });
                } else {
                    swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({text: "Your token has expired! Please sign in again.", icon: "error"}).then(function() {
                        localStorage.clear();
                    window.location.href = window.location.origin+`/login`;
                });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}




export function DashboardAPI() {
    debugger
    var C_token = localStorage.getItem("admintoken")
    return function (dispatch, getState) {
        return getAsios(C_token).post(Dashboard_URL)
            .then((response) => {
                debugger
                if (response.data.success) {
                    dispatch({ type: DashboardRes, payload: response.data });
                } else {
                    swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }
                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                debugger
                if (err.toString().includes("401")) {
                    swal({text: "Your token has expired! Please sign in again.", icon: "error"}).then(function() {
                        localStorage.clear();
                    window.location.href = window.location.origin+`/login`;
                });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function UserlistAPI(data) {
    debugger
    var C_token = localStorage.getItem("admintoken")
    return function (dispatch, getState) {
        return getAsios(C_token).post(Userlist_URL,data)
            .then((response) => {
                debugger
                if (response.data.success) {
                    dispatch({ type: UserRes, payload: response.data });
                } else {
                    swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }
                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                debugger
                if (err.toString().includes("401")) {
                    swal({text: "Your token has expired! Please sign in again.", icon: "error"}).then(function() {
                        localStorage.clear();
                    window.location.href = window.location.origin+`/login`;
                });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function UserstatusAPI(data) {
    debugger
    var C_token = localStorage.getItem("admintoken")
    return function (dispatch, getState) {
        return getAsios(C_token).post(Userstatus_URL, data)
            .then((response) => {
                debugger
                if (response.data.success) {
                    dispatch({ type: UserstatusRes, payload: response.data });
                } else {
                    swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }
                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                debugger
                if (err.toString().includes("401")) {
                    swal({text: "Your token has expired! Please sign in again.", icon: "error"}).then(function() {
                        localStorage.clear();
                    window.location.href = window.location.origin+`/login`;
                });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function AddContentAPI(data) {
    debugger
    var C_token = localStorage.getItem("admintoken")
    return function (dispatch, getState) {
        return getAsios(C_token).post(AddContent_URL, data)
            .then((response) => {
                debugger
                if (response.data.success) {
                    swal({ text: response.data.message, icon: "success" });
                    console.log(response.data)
                    // dispatch({ type: UserstatusRes, payload: response.data });
                } else {
                    swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }
                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                debugger
                if (err.toString().includes("401")) {
                    swal({text: "Your token has expired! Please sign in again.", icon: "error"}).then(function() {
                        localStorage.clear();
                    window.location.href = window.location.origin+`/login`;
                });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function PageDetailsAPI(data) {
    debugger
    var C_token = localStorage.getItem("admintoken")
    return function (dispatch, getState) {
        return getAsios(C_token).post(PageDetails_URL,data)
            .then((response) => {
                debugger
                if (response.data.success) {
                    dispatch({ type: PageDetailsRes, payload: response.data });
                } else {
                    swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }
                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                debugger
                if (err.toString().includes("401")) {
                    swal({text: "Your token has expired! Please sign in again.", icon: "error"}).then(function() {
                        localStorage.clear();
                    window.location.href = window.location.origin+`/login`;
                });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function EmoptionlistAPI(data) {
    debugger
    var C_token = localStorage.getItem("admintoken")
    return function (dispatch, getState) {
        return getAsios(C_token).post(Emoptionlist_URL,data)
            .then((response) => {
                debugger
                if (response.data.success) {
                    dispatch({ type: EmoptionRes, payload: response.data });
                } else {
                    swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }
                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                debugger
                if (err.toString().includes("401")) {
                    swal({text: "Your token has expired! Please sign in again.", icon: "error"}).then(function() {
                        localStorage.clear();
                    window.location.href = window.location.origin+`/login`;
                });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function EmotionAddUpdateAPI(data) {
    debugger
    var C_token = localStorage.getItem("admintoken")
    return function (dispatch, getState) {
        return getAsios(C_token).post(EmotionAddUpdate_URL,data)
            .then((response) => {
                debugger
                if (response.data.success) {
                    dispatch({ type: EmotionAddUpdateRes, payload: response.data });
                } else {
                    swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }
                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                debugger
                if (err.toString().includes("401")) {
                    swal({text: "Your token has expired! Please sign in again.", icon: "error"}).then(function() {
                        localStorage.clear();
                    window.location.href = window.location.origin+`/login`;
                });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function EmotionstatusAPI(data) {
    debugger
    var C_token = localStorage.getItem("admintoken")
    return function (dispatch, getState) {
        return getAsios(C_token).post(Emotionstatus_URL,data)
            .then((response) => {
                debugger
                if (response.data.success) {
                    // dispatch({ type: EmotionstatusRes, payload: response.data });
                } else {
                    swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }
                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                debugger
                if (err.toString().includes("401")) {
                    swal({text: "Your token has expired! Please sign in again.", icon: "error"}).then(function() {
                        localStorage.clear();
                    window.location.href = window.location.origin+`/login`;
                });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function EmotionOrderUpdateAPI(data) {
    debugger
    var C_token = localStorage.getItem("admintoken")
    return function (dispatch, getState) {
        return getAsios(C_token).post(EmotionOrderUpdate_URL,data)
            .then((response) => {
                debugger
                if (response.data.success) {
                } else {
                    swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }
                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                debugger
                if (err.toString().includes("401")) {
                    swal({text: "Your token has expired! Please sign in again.", icon: "error"}).then(function() {
                        localStorage.clear();
                    window.location.href = window.location.origin+`/login`;
                });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function SearchPostAPI(data) {
    debugger
    var C_token = localStorage.getItem("admintoken")
    return function (dispatch, getState) {
        return getAsios(C_token).post(SearchPost_URL,data)
            .then((response) => {
                debugger
                if (response.data.success) {
                    dispatch({ type: SearchPostRes, payload: response.data });
                } else {
                    swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }
                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                debugger
                if (err.toString().includes("401")) {
                    swal({text: "Your token has expired! Please sign in again.", icon: "error"}).then(function() {
                        localStorage.clear();
                    window.location.href = window.location.origin+`/login`;
                });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function ReportedSearchPostAPI(data) {
    debugger
    var C_token = localStorage.getItem("admintoken")
    return function (dispatch, getState) {
        return getAsios(C_token).post(ReportedSearchPost_URL,data)
            .then((response) => {
                debugger
                if (response.data.success) {
                    dispatch({ type: ReportedSearchPostRes, payload: response.data });
                } else {
                    swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }
                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                debugger
                if (err.toString().includes("401")) {
                    swal({text: "Your token has expired! Please sign in again.", icon: "error"}).then(function() {
                        localStorage.clear();
                    window.location.href = window.location.origin+`/login`;
                });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function PoststatusAPI(data) {
    debugger
    var C_token = localStorage.getItem("admintoken")
    return function (dispatch, getState) {
        return getAsios(C_token).post(Poststatus_URL, data)
            .then((response) => {
                debugger
                if (response.data.success) {
                    dispatch({ type: PoststatusRes, payload: response.data });
                } else {
                    swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }
                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                debugger
                if (err.toString().includes("401")) {
                    swal({text: "Your token has expired! Please sign in again.", icon: "error"}).then(function() {
                        localStorage.clear();
                    window.location.href = window.location.origin+`/login`;
                });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function FeedbackistAPI(data) {
    debugger
    var C_token = localStorage.getItem("admintoken")
    return function (dispatch, getState) {
        return getAsios(C_token).post(Feedbackist_URL,data)
            .then((response) => {
                debugger
                if (response.data.success) {
                    dispatch({ type: FeedbackRes, payload: response.data });
                } else {
                    swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }
                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                debugger
                if (err.toString().includes("401")) {
                    swal({text: "Your token has expired! Please sign in again.", icon: "error"}).then(function() {
                        localStorage.clear();
                    window.location.href = window.location.origin+`/login`;
                });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}



export function UserPostlistAPI(data) {
    debugger
    var C_token = localStorage.getItem("admintoken")
    return function (dispatch, getState) {
        return getAsios(C_token).post(UserPostlist_URL,data)
            .then((response) => {
                debugger
                if (response.data.success) {
                    dispatch({ type: UserPostRes, payload: response.data });
                } else {
                    swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }
                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                debugger
                if (err.toString().includes("401")) {
                    swal({text: "Your token has expired! Please sign in again.", icon: "error"}).then(function() {
                        localStorage.clear();
                    window.location.href = window.location.origin+`/login`;
                });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function PostDetailAPI(data) {
    var C_token = localStorage.getItem("admintoken")
    return function (dispatch, getState) {
        return getAsios(C_token).post(PostDetails_URL, data)
            .then((response) => {
debugger
                if (response.data.success) {
                    dispatch({ type: PostDetailRes, payload: response.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        swal({ text: response.data.message, icon: "error", className: "error-modal" });
                        // toast.error(response.data.message, {
                        //     style: { fontSize: 14, top: 40 },
                        //     autoClose: 3000
                        // });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: "Your token has expired! Please sign in again.", icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function UPVoteListAPI(data) {
    var C_token = localStorage.getItem("admintoken")
    return function (dispatch, getState) {
        return getAsios(C_token).post(UPVoteList_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: UPVoteListRes, payload: response.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        swal({ text: response.data.message, icon: "error", className: "error-modal" });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: "Your token has expired! Please sign in again.", icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function DownVoteListAPI(data) {
    var C_token = localStorage.getItem("admintoken")
    return function (dispatch, getState) {
        return getAsios(C_token).post(DownVoteList_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: DownVoteListRes, payload: response.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        swal({ text: response.data.message, icon: "error", className: "error-modal" });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: "Your token has expired! Please sign in again.", icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}
