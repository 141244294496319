import React from "react";
import Navbar from './Navbar';
import { CKEditor } from "ckeditor4-react";
import Loader from './Loader';
import { connect } from "react-redux";
import { AddContentAPI, PageDetailsAPI } from "../actions/globalactions";
import { dispatchLoadingStatus } from '../utils';
import pageloadloader from "../asset/images/page-load-loader.gif";
import swal from 'sweetalert';
import Footer from './Footer';

class Contentmanage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: "",
      page: "privacy-policy"
    }
  }

  componentDidMount() {
    var admintoken = localStorage.getItem("admintoken")
    if (!admintoken) {
      this.props.history.push(`/login`);
    } else {
      // this.props.isLoading(true);
      this.props.PageDetailsAPI({
        "slug": this.state.page
      });
    }

  }

  Peditcall = (e) => {
    debugger
    this.setState({ Pdata: e.editor.getData(), PageData: e.editor.getData(), Error: "",isedit:true });
  }
  TCeditcall = (e) => {
    debugger
    this.setState({ TCdata: e.editor.getData(), PageData: e.editor.getData(), Error: "",isedit:true });
  }
  Heditcall = (e) => {
    debugger
    this.setState({ Hdata: e.editor.getData(), PageData: e.editor.getData(), Error: "",isedit:true });
  }
  ASeditcall = (e) => {
    debugger
    this.setState({ ASdata: e.editor.getData(), PageData: e.editor.getData(), Error: "",isedit:true });
  }

  setpage = (pagename) => {
    debugger
    if(this.state.isedit){
      swal({
        title: "",
        text: 'It looks like you have been editing something, if you leave this page then all the changes will be lost.',
        icon: "warning",
        buttons: true,
        dangerMode: true,
     })
        .then((willDelete) => {
           if (willDelete) {
            this.setState({ page: pagename, Error: "", Pdata: null, TCdata: null, Hdata: null,ASdata:null, PageData: null })
            this.props.PageDetailsAPI({
              "slug": pagename
            });
           } else {
           }
        });
    
      // swal({ text: 'It looks like you have been editing something.\nIf you leave before submit, your changes will be lost.', icon: "warning" });
    }else{
      this.setState({ page: pagename, Error: "", Pdata: null, TCdata: null, Hdata: null,ASdata:null, PageData: null })
      // this.props.isLoading(true);
      this.props.PageDetailsAPI({
        "slug": pagename
      });
    }
    
  }
  addcontent = () => {
    debugger
    if (!this.state.page) {
      this.setState({ Error: "Please select a page" })
    } else if (!this.state.PageData) {
      this.setState({ Error: "Please provide content" })
    } else {
      const data = {
        "page_name": this.state.page,
        "slug": this.state.page,
        "content": this.state.PageData
      }
      this.props.isLoading(true);
      this.props.AddContentAPI(data);
      this.setState({isedit:false})
    }
  }


  componentWillReceiveProps(nextprops) {
    if (nextprops.pageres) {
      if (nextprops.pageres != this.props.pageres) {
        debugger
        if (this.state.page == "privacy-policy") {
          this.setState({isedit:false, Pdata: nextprops.pageres.data ? nextprops.pageres.data.body : "", PageData: nextprops.pageres.data ? nextprops.pageres.data.body : "" })
        } else if (this.state.page == "terms-and-condition") {
          this.setState({isedit:false, TCdata: nextprops.pageres.data ? nextprops.pageres.data.body : "", PageData: nextprops.pageres.data ? nextprops.pageres.data.body : "" })
        } else if (this.state.page == "help") {
          this.setState({isedit:false, Hdata: nextprops.pageres.data ? nextprops.pageres.data.body : "", PageData: nextprops.pageres.data ? nextprops.pageres.data.body : "" })
        } else if (this.state.page == "aboutus") {
          this.setState({isedit:false, ASdata: nextprops.pageres.data ? nextprops.pageres.data.body : "", PageData: nextprops.pageres.data ? nextprops.pageres.data.body : "" })
        }

      }
    }
  }

  render() {
    return (<>
      <Loader />
      <div className="wrapper">
        <Navbar page="content" />
        <div id="content-page" className="content-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-4 col-12">
                <div className="iq-card">
                  <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                      <h4 className="card-title content-mng">Content Management</h4>
                    </div>


                  </div>
                  <div className="iq-card-body">
                    <ul className="m-0 p-0 job-classification">
                      <li><a className={this.state.page == "privacy-policy" ? "active_nav cursor-point" : "cursor-point"} onClick={() => this.setpage("privacy-policy")}>Privacy Policy</a></li>
                      <li><a className={this.state.page == "terms-and-condition" ? "active_nav cursor-point" : "cursor-point"} onClick={() => this.setpage("terms-and-condition")}>Terms & Conditions</a></li>
                      <li><a className={this.state.page == "help" ? "active_nav cursor-point" : "cursor-point"} onClick={() => this.setpage("help")}>Help</a></li>
                      <li><a className={this.state.page == "aboutus" ? "active_nav cursor-point" : "cursor-point"} onClick={() => this.setpage("aboutus")}>About Us</a></li>
                    </ul>
                  </div>
                </div>
              </div>


              <div className="col-lg-9 col-md-8 col-12">
                {this.state.page == "privacy-policy" ? this.state.Pdata != null ?
                  <CKEditor initData={this.state.Pdata} onChange={(e) => this.Peditcall(e)} /> :
                  <div className="col-sm-12 text-center" style={{ marginBottom: 50 }}>
                    <img src={pageloadloader} style={{ height: 80 }} />
                  </div> : null}
                {this.state.page == "terms-and-condition" ? this.state.TCdata != null ?
                  <CKEditor initData={this.state.TCdata} onChange={(e) => this.TCeditcall(e)} /> :
                  <div className="col-sm-12 text-center" style={{ marginBottom: 50 }}>
                    <img src={pageloadloader} style={{ height: 80 }} />
                  </div> : null}
                {this.state.page == "help" ? this.state.Hdata != null ?
                  <CKEditor initData={this.state.Hdata} onChange={(e) => this.Heditcall(e)} /> :
                  <div className="col-sm-12 text-center" style={{ marginBottom: 50 }}>
                    <img src={pageloadloader} style={{ height: 80 }} />
                  </div> : null}
                  {this.state.page == "aboutus" ? this.state.ASdata != null ?
                  <CKEditor initData={this.state.ASdata} onChange={(e) => this.ASeditcall(e)} /> :
                  <div className="col-sm-12 text-center" style={{ marginBottom: 50 }}>
                    <img src={pageloadloader} style={{ height: 80 }} />
                  </div> : null}
                  {(this.state.Pdata || this.state.TCdata || this.state.Hdata || this.state.ASdata) && 
                <div className="col-md-12">
                  <div className="edit-content-btn">
                    {this.state.Error && <p style={{ color: 'red', fontSize: 14, float: 'left' }}>{this.state.Error}</p>}
                    <button type="" className="btn btn-primary mr-3" style={{ marginLeft: 10, marginTop: 5 }} onClick={() => this.addcontent()}>Submit</button>


                  </div>
                </div>}

              </div>

            </div>



          </div>
        </div> </div>    <Footer/>    </>
    )
  }
}


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    isLoading: loading => {
      dispatch(dispatchLoadingStatus(loading));
    },
    AddContentAPI: (data) => { dispatch(AddContentAPI(data)) },
    PageDetailsAPI: (data) => { dispatch(PageDetailsAPI(data)) }

  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.LoadingStatusReducer.loading,
    pageres: state.globalReducer.PageDetailsRes
  }
}
export default connect(mapStateToProps, mapDispatchToProps)((Contentmanage))