import { IS_LOADING ,baseURL} from "./constants";
import axios from "axios";
import moment from "moment";
import { EncryptStorage } from 'encrypt-storage';

export const encryptStorage = EncryptStorage('secret_key', {});

export function getAsios(token) {
  if (token) {
    return axios.create({
      baseURL: baseURL,
      headers: {
        Authorization: token
      }
    });
  } else {
    return axios.create({
      baseURL: baseURL,
      // headers: {
      //   Authorization: "bearer " + token
      //   }
    });
  }

}

export function dispatchLoadingStatus(loading) {
  return function (dispatch, getState) {
    dispatch({ type: IS_LOADING, payload: { loading: loading } });
  };
}


export function isValidNumber(num) {
  
  let isValidNumber = /^[0-9]+$/.test(num);
  return isValidNumber;
}


export function diff_minutes(prevDate, datedisplay) {
  var d1 = moment(prevDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
  var d2 = moment().format('YYYY-MM-DD');
  var diff = moment(d2).diff(d1, 'days')
  if (diff > 30) {
    return moment(prevDate, 'YYYY-MM-DD').format('MMMM DD, YYYY');
  } else {
    var d = new Date(prevDate);
    const diff = Number(new Date()) - d;

    const minute = 60 * 1000;
    const hour = minute * 60;
    const day = hour * 24;
    const month = day * 30;
    const year = day * 365;
    switch (true) {
      case diff < minute:
        const seconds = Math.round(diff / 1000);
        return seconds > 1 ? `${seconds} seconds ago` : 'few seconds ago';
      case diff < hour:
        return Math.round(diff / minute) == 1 ? Math.round(diff / minute) + ' minute ago' : Math.round(diff / minute) + ' minutes ago';
      case diff < day:
        return Math.round(diff / hour) == 1 ? Math.round(diff / hour) + ' hour ago' : Math.round(diff / hour) + ' hours ago';
      case diff < month:
        return Math.round(diff / day) == 1 ? Math.round(diff / day) + ' day ago' : Math.round(diff / day) + ' days ago';
      case diff < year:
        return Math.round(diff / month) == 1 ? Math.round(diff / month) + ' month ago' : Math.round(diff / month) + ' months ago';
      case diff > year:
        return Math.round(diff / year) == 1 ? Math.round(diff / year) + ' year ago' : Math.round(diff / year) + ' years ago';
      default:
        return "";
    }
  }
}


// Created by NiraliBThummar 
export function numFormatter(num) {
  if(num > 999 && num < 1000000){
      return (num/1000).toFixed(1) + 'K'; // thousand
  }else if(num >= 1000000 && num < 1000000000){
      return (num/1000000).toFixed(1) + 'M'; // million
  }else if(num >= 1000000000 && num < 1000000000000){
      return (num/1000000000).toFixed(1) + 'B'; // billion
  }else if(num >= 1000000000000){
      return (num/1000000000000).toFixed(1) + 'T'; // trillion
  }else if(num < 900){
      return num; // if value < 1000, nothing to do
  }
}
