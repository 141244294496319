import React from 'react';
import { Chart } from 'react-charts';


export default function AdminChart(props) {
  const data = React.useMemo(
    () => [
      {
        label: props.tooltip,
        data: props.datan,
        color: props.color
      }
    ],
    []
  )

  const series = React.useMemo(
    () => ({
      type: 'bar'
    }),
    []
  )
  const axes = React.useMemo(
    () => [
      { primary: true, type: 'ordinal', position: 'bottom' },
      { position: 'left', type: 'linear', stacked: true }
    ],
    []
  )
  return(
        <div
      style={{
        height: '400px'
      }}
    >
      <Chart data={data}series={series} axes={axes} tooltip />
     
   </div>
  )
}





