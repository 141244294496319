import React, { Component } from "react";
import { connect } from "react-redux";
import { role } from "../constants";
import { EmoptionlistAPI, EmotionAddUpdateAPI, EmotionstatusAPI,EmotionOrderUpdateAPI} from "../actions/globalactions";
import { dispatchLoadingStatus } from '../utils';
import Loader from "./Loader";
import Navbar from './Navbar';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import pageloadloader from "../asset/images/page-load-loader.gif";
import Modal from "react-responsive-modal";
import user09 from "../asset/images/user1.svg";
import Footer from './Footer';
import swal from 'sweetalert';
import ReactTooltip from "react-tooltip";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};


const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    background: isDragging ? "skyblue" : "",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "" : ""
});

class EmotionOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: null,
            emotionid:'',
            error:''
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    componentDidMount(){
        this.props.EmoptionlistAPI({
            "device_type": role
        })
    }

    componentWillReceiveProps(nextprops){
        if(nextprops.emoptionlist){
            if(nextprops.emoptionlist!=this.props.emoptionlist){
                debugger
                this.setState({items:nextprops.emoptionlist.data})
            }
        }

        if(nextprops.emoptionaddupdate){
            if(nextprops.emoptionaddupdate!=this.props.emoptionaddupdate){
                debugger
                var itemsobj = this.state.items;
                var resobj = nextprops.emoptionaddupdate.data;
                if(this.state.edit){
                    debugger
                    var foundIndex = itemsobj.findIndex(x => x._id == this.state.emotionid);
                    itemsobj[foundIndex] = resobj;
                }else{
                    itemsobj.push(resobj);
                }
                this.setState({items:itemsobj,edit:false,open:false,emotionname:'',emotionicon:'',emotionid:'',error:'',emotionfile:''})
            }
        }
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        );

        this.setState({
            items
        });
var postdata = [];
items.map((i,index)=>postdata.push({id:i._id, position:index}))
this.props.EmotionOrderUpdateAPI(postdata)
    }


    handleChange=(event)=>{
        if (event.target.files && event.target.files[0]) {
            var file=event.target.files
            if (file[0].name.toLowerCase().includes("jpg") ||
            file[0].name.toLowerCase().includes("png") ||
            file[0].name.toLowerCase().includes("svg") ||
            file[0].name.toLowerCase().includes("jpeg")){
                this.setState({
                    emotionfile:event.target.files[0],
                    emotionicon: URL.createObjectURL(event.target.files[0]),
                    error:''
                });
            }else{
                this.setState({error:"The photo must be a file of type: jpg, png, jpeg, svg."})
            }
          }
    }

    changestatus=(item)=>{
        swal({
            title: "",
            text: item.status==1? "Are you sure you want to inactive this emotion?" : "Are you sure you want to active this emotion?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    debugger
        var data={
            "id": item._id,
            "status":item.status==1?0:1,
            "device_type": role
        }
        this.props.EmotionstatusAPI(data);
        var list = []
        this.state.items && this.state.items.filter(j => {
            if (j._id == item._id) {
                item.status = item.status == 1 ? 0 : 1
            }
            list.push(j)
        })
        this.setState({items:list})
    }
});
    }

    addupdateEmotion=()=>{
        if(!this.state.edit && !this.state.emotionfile){
            this.setState({error:'Plese select emotion photo'})
        }else if(!this.state.emotionname){
            this.setState({error:'Please Enter emotion title'})
        }else{
            var form_data = new FormData()
            form_data.append("id", this.state.emotionid);
            form_data.append("name", this.state.emotionname);
            form_data.append("device_type", role);
            form_data.append("iconFile", this.state.emotionfile);
            this.props.isLoading('true');
            this.props.EmotionAddUpdateAPI(form_data);            
        }
    }

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
        return (
            <>
    <Loader />
    <div className="wrapper">
        <Navbar page="emotion" />

        <div id="content-page" className="content-page post-searchpage">
            <div className="container ">
            <div className="add-new-emotion"><button  className="btn btn-primary mb-3" onClick={()=>this.setState({open:true})}>Add Emotion</button></div>
            {this.state.items ? this.state.items.length>0 ?
    <DragDropContext onDragEnd={this.onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {this.state.items&&this.state.items.map((item, index) => (
          
             <Draggable className="" key={item._id} draggableId={item._id} index={index}>
                 
                {(provided, snapshot) => (
               <div className=""
                 ref={provided.innerRef}
                 {...provided.draggableProps}
                 {...provided.dragHandleProps}
                 style={getItemStyle(
                   snapshot.isDragging,
                   provided.draggableProps.style
                 )}
               >
                    <div className="iq-card iq-card-block iq-card-stretch custom-card-padding">
                        <div className="iq-card-body">
                            <ul className="emotion-ul"><li className="emotion-li-1 ">
            <img className="rounded-circle" src={item.icon} height={50} width={50} style={{ marginRight: 10 }} />
                <span>{item.name}</span></li>
               <li className="emotion-li-3"> 
               {/* <span className="">Active</span>  */}
               {item.status==1? 
                 <label className="active-label-emotion cursor-point ml-1" onClick={()=>this.changestatus(item)}>Active</label>
                //  <input type="checkbox" checked={true} className="cursor-point ml-1  check-emo"  />
                 :
                 <label className="inactive-label-emotion cursor-point ml-1" onClick={()=>this.changestatus(item)}>In Active</label>
                //  <input type="checkbox" checked={false} className="cursor-point ml-1 check-emo" onClick={()=>this.changestatus(item)}/>
                 }</li>
                  <li className="emotion-li-2" style={{cursor:'pointer'}} onClick={()=>this.setState({edit:true,open:true,emotionname:item.name,emotionicon:item.icon,emotionid:item._id})}> 
                  <a  data-tip data-for="editemtn"><i style={{color:'#007bff'}} className="fa fa-pencil upload-button ml-1" ></i></a>
                  <ReactTooltip id="editemtn" type="dark">
           <span>Edit Emotion</span>
         </ReactTooltip>
                  </li></ul>
                 </div>
                 </div>
               </div>
                )}
              </Draggable>

            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
    :<p>No Data Found</p>: 
    <div className="col-sm-12 text-center" style={{ marginBottom: 50 }}>
    <img src={pageloadloader} style={{ height: 80 }} />
</div>}
    </div>
    </div>
    <Modal
                classNames={{
                    overlay: "customOverlay",
                    modal: "customModal"
                }}
                open={this.state.open}
                center
                onClose={() => { }}
                showCloseIcon={false}
            >
                {this.state.open &&
                    <div className="modal fade show create-post liked-by-user" id="post-modal" tabIndex="-1" role="dialog" aria-labelledby="post-modalLabel" aria-modal="true" style={{ paddingRight: '8', display: 'block' }}>
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                
                                <h5 className="modal-title" id="post-modalLabel">{!this.state.edit?'Add':'Edit'} Emotion</h5>
                               
                                
                               <div className="custom-modal-close"><button type="button" className="btn  close-modal" data-dismiss="modal" onClick={() => this.setState({edit:false,open:false,emotionname:'',emotionicon:'',emotionid:'',emotionfile:'',error:''})}><i className="ri-close-fill"></i></button></div>
                                </div>
                                <div className="modal-body">
                                    <div className="profile-img-edit">
                                <img src={this.state.emotionicon?this.state.emotionicon:user09} />
                                   <div className="p-image">  <label htmlFor="imagepic" className="cursor-point"><i className="fa fa-camera upload-button"></i></label></div>
                                   </div>
                                             <input id="imagepic" type="file" className="form-control"  name="Image" onClick={(event)=> { 
               event.target.value = null
          }} onChange={(e) => this.handleChange(e)} style={{ display: 'none' }} />
                               <div className="">
                                <input type="text" className="form-control"  value={this.state.emotionname} onChange={(e)=>this.setState({emotionname:e.target.value&&e.target.value.trim()==""?"":e.target.value,error:''})} maxLength={30}/>
                           {this.state.error&&<p style={{fontSize:12,color:'red'}}>{this.state.error}</p>}
                                   <button  className="btn btn-primary add-new-emot" onClick={()=>this.addupdateEmotion()}>{!this.state.edit?'Add':'Update'}</button>
                                   </div>
                                </div>
                            </div>
                        </div></div>
                }
            </Modal>
            {this.state.open && <div className="modal-backdrop fade show"></div>}
  </div>
  <Footer/>
  </>
        );
    }
}
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        isLoading: loading => {
            dispatch(dispatchLoadingStatus(loading));
        },
        EmoptionlistAPI: (data) => { dispatch(EmoptionlistAPI(data)) },
        EmotionAddUpdateAPI: (data) => { dispatch(EmotionAddUpdateAPI(data)) },
        EmotionstatusAPI: (data) => { dispatch(EmotionstatusAPI(data)) },
        EmotionOrderUpdateAPI: (data) => { dispatch(EmotionOrderUpdateAPI(data)) },
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        loading : state.LoadingStatusReducer.loading,
        emoptionlist : state.globalReducer.EmoptionRes,
        emoptionaddupdate : state.globalReducer.EmotionAddUpdateRes,
        emoptionstatus : state.globalReducer.EmotionstatusRes
    }
}
export default connect(mapStateToProps, mapDispatchToProps)((EmotionOrder))