import React, { useState, useEffect, useMemo, useCallback } from "react";
import DataTable from "react-data-table-component";
import swal from 'sweetalert';
import user09 from "../asset/images/user1.svg"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { UserPostlistAPI, PoststatusAPI, UserstatusAPI } from "../actions/globalactions";
import Navbar from './Navbar';
import Loader from './Loader';
import pageloadloader from "../asset/images/page-load-loader.gif";
import Modal from "react-responsive-modal";
import { role, weburl } from "../constants";
import moment from "moment";
import Footer from './Footer';
import { Img } from 'react-image';
import ReactTooltip from "react-tooltip";



const Userdetails = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [userRes, setuserRes] = useState(null);
    const [userdata, setuserdata] = useState(null);
    const [open, setopen] = useState(false);
    const [userphoto, setuserphoto] = useState("");
    const [filter, setfilter] = useState("");
    const [userID, setuserID] = useState("");
    const userResapi = useSelector(state => state.globalReducer.UserPostRes);
    const userstateapi = useSelector(state => state.globalReducer.UserstatusRes);

    const [page, setPage] = useState(1);
    const countPerPage = 10;
    const [userscount, setuserscount] = useState(0);

    const columns = [
        // {
        //     name: "Sr. No.",
        //     selector: "srno",
        //     ignoreRowClick: true,
        //     grow:1
        // },
        // {
        //     name: "Profile",
        //     selector: (row) => row.user_data.photo,
        //     ignoreRowClick: true,
        //     grow:1,
        //     cell: (row) =>
        //         row.name ? (
        //             <>
        //                 <a className="cursor-point" onClick={() => {debugger; setopen(true); setuserphoto(row.user_data.photo); }}>
        //                     <Img
        //                         className="rounded-circle img-fluid avatar-40 mr-3"
        //                         src={row.user_data.photo}
        //                         loader={<img src={user09} className="rounded-circle img-fluid avatar-40" />}
        //                         unloader={<img src={user09} className="rounded-circle img-fluid avatar-40" />}
        //                     /></a></>
        //             //  <> <img
        //             //     onClick={() => alert("nsdfncjsd")}
        //             //     height="50px"
        //             //     width="50px"
        //             //     src={row.photo}
        //             //   />{row.name}</>
        //         ) : (
        //             ""
        //         )
        // },

        {
            name: "Content",
            selector: (row) => row.content,
            grow: 2,
            ignoreRowClick: true,
            cell: (row) => <p className="user-Name">{row.content} {row.is_anonymous=="1"&&<><br/> <sapn style={{color: '#007bff',lineHeight:"2"}}>"Anonymously Posted"</sapn></>}</p>
        },
        {
            name: "Emotion",
            selector: (row) => row.emotion_icon,
            // sortable: true,
            grow: 1,
            ignoreRowClick: true,
            cell: (row) => row.emotion_icon == "" ? 
            <><a data-tip data-for="noemtn" style={{ padding: 5, margin: 0}}>-</a> 
                <ReactTooltip id="noemtn" type="dark">
            <span>No Emotion Selected</span>
          </ReactTooltip></>
            : <> <img data-tip data-for={`emtn${row._id}`} src={row.emotion_icon} className="rounded-circle img-fluid avatar-40 mr-3" />
            <ReactTooltip id={`emtn${row._id}`} type="dark">
            <span>{row.emotion_name}</span>
          </ReactTooltip></>
        },
        {
            name: "Date of post",
            selector: (row) => row.created_at,
            ignoreRowClick: true,
            grow: 1,
            cell: (row) => <p className="user-Name">{moment(row.created_at).format('MMMM DD, YYYY')}</p>
        },
        {
            name: '',
            ignoreRowClick: true,
            selector: (row) => row._id,
            cell: (row) => <>
            <a target="_blank" data-tip data-for="postdetail" href={`/postdetail/${row._id}`} style={{ padding: 5, margin: 0 }}>
                <i style={{ fontSize: 16 }} className="ri-share-box-line "></i></a> 
                <ReactTooltip id="postdetail" type="dark">
            <span>View Post Details</span>
          </ReactTooltip></>
        },
        {
            name: "Action",
            grow: 1,
            selector: (row) => row.status,
            ignoreRowClick: true,
            cell: (row, index) =>
                row.status == 1 ?
                    <label className="active-label cursor-point" onClick={() => activeclick(row)} >Active</label>
                    : <label className="inactive-label cursor-point" onClick={() => activeclick(row)}>In Active</label>
        }
    ];

    useEffect(() => {
        setuserRes(null);
        // return () => {
        //     localStorage.setItem("gotouser",false)
        //   }
    }, [])

    function activeclickforuser(i) {
        debugger
        swal({
            title: "",
            text: i.is_active == 1 ? "Are you sure you want to inactive this user?" : "Are you sure you want to active this user?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    debugger
                    dispatch(UserstatusAPI({
                        "user_id": i._id,
                        "status": i.is_active == 1 ? 0 : 1
                    }));
                    var temp = userdata
                    temp.is_active = temp.is_active == 1 ? 0 : 1
                    setuserdata(temp)

                    //   this.setState({ userRes: list })
                }
            });

    }

    function activeclick(i) {
        debugger
        swal({
            title: "",
            text: i.status == 1 ? "Are you sure you want to inactive this post?" : "Are you sure you want to active this post?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    debugger
                    dispatch(PoststatusAPI({
                        "id": i._id,
                        "status": i.status == 1 ? 0 : 1,
                        "device_type": role
                    }));
                    var list = []
                    userRes.filter(j => {
                        if (j._id == i._id) {
                            j.status = j.status == 1 ? 0 : 1
                        }
                        list.push(j)
                    })
                    debugger
                    setuserRes(list);
                }
            });

    }

    useEffect(() => {
        debugger
        var admintoken = localStorage.getItem("admintoken")
        if (!admintoken) {
            history.push(`/login`);
        } else {
            setuserID(props.location.state ? props.location.state : '')
            window.scrollTo(0,0);
        }
    }, []);

    useEffect(() => {
        calluserlist()
    }, [userID])

    const calluserlist = () => {
        if (userID) {
            const Postdata = {
                "user_id": userID,
                "page": page,
                "limit": countPerPage,
                "content": filter ? filter : "",
                "device_type": role
            }
            dispatch(UserPostlistAPI(Postdata))
        }

    };


    useEffect(() => {
        calluserlist();
    }, [page]);

    useEffect(() => {
        debugger
        if (filter == '' || filter.length > 2) {
            if (document.getElementById("pagination-first-page")) {
                if (document.getElementById("pagination-first-page").disabled) {
                    calluserlist();
                } else {
                    document.getElementById("pagination-first-page").click();
                }
            } else {
                calluserlist();
            }
        }
    }, [filter]);

    useEffect(() => {
        debugger
        if (userResapi != null) {
            if (userResapi.user_data._id == userID) {
                setuserdata(userResapi.user_data);
                if (userResapi.count > 0) {
                    if (userResapi.data) {
                        if (userResapi.data.length > 0) {
                            setuserRes(userResapi.data);
                            setuserscount(userResapi.count);
                        } else {
                            setuserRes([]);
                            setuserscount(0);
                        }
                    } else {
                        setuserRes([]);
                        setuserscount(0);
                    }
                } else {
                    setuserRes([]);
                    setuserscount(0);
                }
            } else {
                setuserRes(null);
                setuserscount(0);
            }
        }
    }, [userResapi]);
    useEffect(() => {
        debugger
        if (userRes != null) {
            setuserRes(userRes);
            setuserscount(userResapi.count);
        }
    }, [userstateapi]);

    function searchfilter(e) {
        debugger
        setfilter(e.target.value.trim() == '' ? "" : e.target.value)
    }
    return (<>
        <Loader />
        <div className="wrapper">
            <Navbar page="details" />

            <div id="content-page" className="content-page">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                        {userdata ?
                            <div className="iq-card">
                                <div className="iq-card-header d-flex">

                                    {/* <div className="iq-header-title">
                                        <h4 className="card-title">User's Details</h4>
                                    </div> */}
                                 {userdata ?
                                            <Img
                                                className="img-fluid img-rounded user-profile-img-inner "
                                                style={{ height: 250, width: 250 ,borderRadius:20 }}
                                                src={userdata.photo}
                                                loader={<img src={user09} className="img-fluid img-rounded user-profile-img-inner " style={{ height: 250, width: 250 ,borderRadius:20 }} />}
                                                unloader={<img src={user09} className="img-fluid img-rounded user-profile-img-inner " style={{ height: 250, width: 250 ,borderRadius:20 }} />}
                                            />
                                            : null}
                                            <div className="user-profile-info">
                                        { userdata &&  <h4>  {userdata.name}</h4>}
                                        { userdata && userdata.username && <h6>  {userdata.username}</h6>}
                                        {userdata && <h6 ><i class="ri-mail-line mr-3"></i>{userdata.email}</h6>}
                                        {userdata && userdata.phone ? 
                                        userdata.country_code ?
                                        <h6> <i class="ri-phone-line mr-3 "></i>{userdata.country_code +"-"+userdata.phone}</h6>
                                        :<h6> <i class="ri-phone-line mr-3 "></i>{userdata.phone}</h6> :null}
                                        {userdata && userdata.dob && <h6> <i class="ri-calendar-line mr-3"></i>{moment(userdata.dob).format('MMMM DD, YYYY')}</h6>}
                                        {userdata && userdata.bio && <p style={{wordBreak:'break-word'}}>{userdata.bio}</p>}
                                        
                                    {userdata ? userdata.is_active == 1 ?
                                            <label className="active-label without-before cursor-point mt-3" onClick={() => activeclickforuser(userdata)} >Active</label>
                                            : <label className="inactive-label without-before cursor-point mt-3" onClick={() => activeclickforuser(userdata)}>In Active</label>
                                            : null}</div> 
                                </div>
                                <div className="iq-card-body">
                                    <div className="table-responsive">
                                    {userRes && <h4 className="card-title">User's Posts</h4>}
                                        
                                             <div className="custom-table-search"><input type="text" className="form-control custom-search col-3" placeholder="search by content" value={filter} onChange={(e) => searchfilter(e)} /></div>
                                          

                                        {userRes ?
                                            userRes.length > 0 ?
                                                <DataTable
                                                    fixedHeader={true}
                                                    columns={columns}
                                                    data={userRes}
                                                    // sortIcon={<i style={{marginLeft:5}} className="fa fa-sort" aria-hidden="true"></i>}
                                                    pagination
                                                    highlightOnHover
                                                    paginationServer
                                                    paginationResetDefaultPage={true}
                                                    paginationTotalRows={userscount}
                                                    paginationPerPage={countPerPage}
                                                    paginationComponentOptions={{
                                                        noRowsPerPage: true
                                                    }}
                                                    onChangePage={page => setPage(page)}
                                                />
                                                : <p style={{ textAlign: 'center', padding: 10 }}>No Data Found</p>
                                            : <div className="col-sm-12 text-center" style={{ marginBottom: 50 }}>
                                                <img src={pageloadloader} style={{ height: 80 }} />
                                            </div>}

                                    </div>

                                </div>
                            </div>
                            :<div className="col-sm-12 text-center" style={{ marginBottom: 50 }}>
                            <img src={pageloadloader} style={{ height: 80 }} />
                        </div>}
                        </div>

                    </div>
                </div>
            </div>
            <Modal
                classNames={{
                    overlay: "customOverlay",
                    modal: "customModal"
                }}
                open={open}
                center
                onClose={() => { }}
                showCloseIcon={false}
            >
                {open &&
                    <div className="modal fade show create-post liked-by-user" id="post-modal" tabIndex="-1" role="dialog" aria-labelledby="post-modalLabel" aria-modal="true" style={{ paddingRight: '8', display: 'block' }}>
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">

                                    <h5 className="modal-title" id="post-modalLabel">Profile Picture</h5>


                                    <div className="custom-modal-close"><button type="button" className="btn  close-modal" data-dismiss="modal" onClick={() => { setopen(false); setuserphoto("") }}><i className="ri-close-fill"></i></button></div>
                                </div>
                                <div className="modal-body">
                                    <Img
                                        className="img-fluid img-rounded user-manage-img"
                                        style={{ height: 300, width: 300 }}
                                        src={userphoto}
                                        loader={<img src={user09} className="img-fluid" style={{ height: 300, width: 300 }} />}
                                        unloader={<img src={user09} className="img-fluid" style={{ height: 300, width: 300 }} />}
                                    />
                                </div>
                            </div>
                        </div></div>
                }
            </Modal>
            {open && <div className="modal-backdrop fade show"></div>}
        </div>
        <Footer />
    </>
    )
}

export default Userdetails