import React from "react";
import { connect } from "react-redux";

import { basepath, devicetype,errormsg } from "../constants";
import { dispatchLoadingStatus, isValidNumber } from'../utils';
import { ForgotPassVerifyAPI,ForgotPassAPI} from "../actions/globalactions";
import swal from 'sweetalert';
import Loader from "./Loader";

class OTP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      one: '',
      two: '',
      three: '',
      four: ''
    }
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
  var admintoken = localStorage.getItem("admintoken")
  if (!admintoken && localStorage.getItem("Aforgot_pass")=="true") {
    } else {
      this.props.history.push(`/dashboard`);
    }
  }
  handleChange(event) {
    if (isValidNumber(event.target.value)) {
      this.setState({
        [event.target.id]: event.target.value,
        Error: ""
      });
      if (event.target.id == 'one') {
        this.refs.two.focus()
      } else if (event.target.id == 'two') {
        this.refs.three.focus()
      } else if (event.target.id == 'three') {
        this.refs.four.focus()
      }
    } else {
      this.setState({
        [event.target.id]: ""
      });
    }
    debugger
    if (event.target.value == "") {
      if (event.target.id == 'two') {
        this.refs.one.focus()
      } else if (event.target.id == 'three') {
        this.refs.two.focus()
      } else if (event.target.id == 'four') {
        this.refs.three.focus()
      }
    }
  }


  resendCode(remaining){
    debugger
      var femail = localStorage.getItem("AFemail")
      const postdata = {
        "email": femail,
        "device_type":devicetype,
        "is_admin" : 1
    }
    this.props.ForgotPassAPI(postdata,true)
    this.Countdown(remaining)
  }
  Countdown(remaining){
    let timerOn = true;
    var m = Math.floor(remaining / 60);
    var s = remaining % 60;
    
    m = m < 10 ? '0' + m : m;
    s = s < 10 ? '0' + s : s;
    //document.getElementById('timer').innerHTML = m + ':' + s;
    if(m==0 && s==0){
      this.setState({timer :''})
    }else{
      this.setState({timer : m + ':' + s})
    }
    
    remaining -= 1;
    
    if(remaining >= 0 && timerOn) {
      setTimeout(()=>{
        this.Countdown(remaining);
      }, 1000);
      return;
    }
  
    if(!timerOn) {
      // Do validate stuff here
      return;
    }
    
    // Do timeout stuff here
    //alert('Timeout for otp');
  }

  Validation=()=> {
    debugger
    if (this.state.one && this.state.two && this.state.three && this.state.four) {
      let otp = this.state.one + this.state.two + this.state.three + this.state.four
        var mail = localStorage.getItem("AFemail");
        if(mail){
        this.props.isLoading(true);
        const postdata = {
          "email": localStorage.getItem("AFemail"),
          "device_type": devicetype,
          "otp": otp,
          "is_admin" : 1
        }
        this.props.ForgotPassVerifyAPI(postdata);
      }
    } else {
      this.setState({ Error: "Please enter OTP" })
    }
  }

  EnterPressCall = (e) => {
    if (e.charCode == 13 || e.which == 13) {
      this.Validation()
    }
  }
  render() {
    debugger
    return (
      <>
      <Loader />
       <section className="sign-in-page bg-white login" >
          <div className="container">
              <div className="row">
                  <div className="col-sm-12 align-self-center">
                    <div className="sign-in-main" >
                      <div className="sign-in-from">
                        <a className="sign-in-logo text-center mb-2" href="#"><img src="images/logo.png" className="img-fluid" alt="logo"/></a>
                          <h1 className="mb-0">Enter OTP</h1>
                          <div className="row mt-4 ">
                      
                      <div className="col-md-3 ">
                       <div className="form-group">
                        <input type="text" ref='one'  className="form-control mb-0 text-center" maxLength="1" id="one" value={this.state.one} onChange={this.handleChange}  onKeyPress={(e) => this.EnterPressCall(e)}/> 
            
                          </div>
                          </div>
                          <div className="col-md-3 ">
                       <div className="form-group">
                       <input type="text" ref='two'  className="form-control mb-0 text-center" maxLength="1" id="two" value={this.state.two} onChange={this.handleChange}  onKeyPress={(e) => this.EnterPressCall(e)}/>
            
                          </div>
                          </div>
                          <div className="col-md-3 ">
                       <div className="form-group">
                         <input type="text" ref='three'  className="form-control mb-0 text-center" maxLength="1" id="three" value={this.state.three} onChange={this.handleChange}  onKeyPress={(e) => this.EnterPressCall(e)}/>
            
                          </div>
                          </div>
                          <div className="col-md-3 ">
                       <div className="form-group">
                        <input type="text" ref='four'  className="form-control mb-0 text-center" maxLength="1" id="four" value={this.state.four} onChange={this.handleChange}  onKeyPress={(e) => this.EnterPressCall(e)}/>
            
                          </div>
                          </div>
                          
                          
                          <div className="col-md-12 ">    {this.state.Error && <p style={{ color: 'red', fontSize: 14, float: 'left' }}>{this.state.Error}</p>}</div>
               
                         
                      <div className="col-md-12">
                        <div className="d-inline-block w-100">
                          <div className=" d-inline-block mt-2 pt-1">
                          {this.state.timer?<div className="forget-link"> <a className="forget">Time left : {this.state.timer}</a></div>
                              : 
                            <a  onClick={()=>this.resendCode(120)} className="float-left cursor-point">Resend Code</a>}
                          </div>
                          <button type="submit" className="btn btn-primary float-right" onClick={() => this.Validation()}>Verify</button>
                        </div>
                      </div>
                            </div>
                    </div>
                      </div>
                    </div>
                  
                </div>
            </div>
        </section>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    isLoading: loading => {
      dispatch(dispatchLoadingStatus(loading));
    },
    ForgotPassAPI: (postdata,resend) => { dispatch(ForgotPassAPI(postdata,resend)) },
    ForgotPassVerifyAPI: (postdata) => { dispatch(ForgotPassVerifyAPI(postdata)) }
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.LoadingStatusReducer.loading
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OTP);