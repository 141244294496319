import React, { useState, useEffect, useMemo, useCallback } from "react";
import DataTable from "react-data-table-component";
import swal from 'sweetalert';
import user09 from "../asset/images/user1.svg"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { UserlistAPI, UserstatusAPI } from "../actions/globalactions";
import Navbar from './Navbar';
import Loader from './Loader';
import pageloadloader from "../asset/images/page-load-loader.gif";
import Modal from "react-responsive-modal";
import { role } from "../constants";
import moment from "moment";
import Footer from './Footer';
import { Img } from 'react-image';

import ReactTooltip from "react-tooltip";
import { de } from "date-fns/locale";


const Usermanage = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [userRes, setuserRes] = useState(null);
    const [open, setopen] = useState(false);
    const [userphoto, setuserphoto] = useState("");
    const [filter, setfilter] = useState(localStorage.getItem("searchtext")?localStorage.getItem("searchtext"):"");
    const [filterdata, setfilterdata] = useState(null);
    const userResapi = useSelector(state => state.globalReducer.UserRes);
    const userstateapi = useSelector(state => state.globalReducer.UserstatusRes);

    const [page, setPage] = useState(localStorage.getItem("pagenum")?parseInt(localStorage.getItem("pagenum")):1);
    const countPerPage = 10;
    const [userscount, setuserscount] = useState(0);

    const columns = [
        // {
        //     name: "Sr. No.",
        //     selector: "srno",
        //     ignoreRowClick: true,
        //     grow:1
        // },
        {
            name: "Profile",
            selector: (row) => row.photo,
            ignoreRowClick: true,
            grow:1,
            cell: (row) =>
                row.name ? (
                    <>
                        {/* <a className="cursor-point" onClick={() => {debugger; setopen(true); setuserphoto(row.photo); }}> */}
                        <a className="cursor-point" data-tip data-for="viewuser" onClick={() => gotouser(row)}>
                                <Img
                                className="rounded-circle img-fluid avatar-40 mr-3"
                                src={row.photo}
                                loader={<img src={user09} className="rounded-circle img-fluid avatar-40" />}
                                unloader={<img src={user09} className="rounded-circle img-fluid avatar-40" />}
                            /></a> <ReactTooltip id="viewuser" type="dark">
                            <span>View User Details</span>
                          </ReactTooltip></>
                    //  <> <img
                    //     onClick={() => alert("nsdfncjsd")}
                    //     height="50px"
                    //     width="50px"
                    //     src={row.photo}
                    //   />{row.name}</>
                ) : (
                    ""
                )
        },
        {
            name: "Name",
            selector: (row) => row.name,
            // sortable: true,
            grow:2,
            ignoreRowClick: true,
            cell: (row) =><p className="user-Name">{row.name}</p>
        },
        {
            name: "Email",
            selector: (row) => row.email,
            grow:2,
            ignoreRowClick: true,
            cell: (row) =><p className="user-Name">{row.email}</p>
        },
        {
            name: "Contact No.",
            selector: (row) => row.phone,
            ignoreRowClick: true,
            grow:1,
            cell: (row) => row.phone ? row.country_code ?<p className="user-Name">{row.country_code +"-"+row.phone}</p>:<p className="user-Name">{row.phone}</p> : <p className="user-Name">{"-"}</p>
        },
        {
            name: "Registration date",
            ignoreRowClick: true,
            grow:1,
            selector: (row) => row.created_at,
            cell: (row) =><p className="user-Name">{moment(row.created_at).format('MMMM DD, YYYY')}</p>
        },
        {
            name: "Action",
            grow:1,
            selector: (row) => row.is_active,
            ignoreRowClick: true,
            cell: (row, index) =>
                row.is_active == 1 ?
                    // <input type="checkbox"className="cursor-point" checked={true} onChange={() => activeclick(row)} />
                    <label className="active-label cursor-point" onClick={() => activeclick(row)} >Active</label>
                    : <label className="inactive-label cursor-point" onClick={() => activeclick(row)}>In Active</label>
                    //  <input type="checkbox" className="cursor-point" checked={false} onChange={() => activeclick(row)} />
        }
    ];


    function gotouser(row){
        localStorage.setItem("pagenum",page)
        localStorage.setItem("searchtext",filter)
        history.push({pathname: `/detail`,state: row._id})
    }

    function activeclick(i) {
        debugger
        swal({
            title: "",
            text: i.is_active == 1 ? "Are you sure you want to inactive this user?" : "Are you sure you want to active this user?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    debugger
                    dispatch(UserstatusAPI({
                        "user_id": i._id,
                        "status": i.is_active == 1 ? 0 : 1
                    }));
                    var list = []
                    userRes && userRes.filter(j => {
                        if (j._id == i._id) {
                            j.is_active = j.is_active == 1 ? 0 : 1
                            // list[index]["is_active"] = list[index]["is_active"] == 1 ? 0 : 1
                        }
                        list.push(j)
                    })

                    setuserRes(list)
                   
                    //   this.setState({ userRes: list })
                }
            });

    }


    useEffect(() => {
        debugger
        var admintoken = localStorage.getItem("admintoken")
        if (!admintoken) {
            history.push(`/login`);
        }else{
            debugger
            // if(localStorage.getItem("searchtext"))
            // {setfilter(localStorage.getItem("searchtext"))}
            // if(localStorage.getItem("pagenum"))
            // {setPage(parseInt(localStorage.getItem("pagenum")))}  
        }
    }, []);

    const calluserlist = () => {
        var data = {
          "page": page,
          "limit": countPerPage,
          "search_text":filter?filter:"",
          "device_type": role
      }
      if(page && page!=0){
          if(localStorage.getItem("searchtext")){
            localStorage.removeItem("searchtext")
          }
            dispatch(UserlistAPI(data))        
      }    
      };


      useEffect(() => {
        console.log('page change..')
        calluserlist();
      }, [page]);

      useEffect(() => {
          debugger          
          if(filter=='' || filter.length>2){
                if(document.getElementById("pagination-first-page")){
                    if(document.getElementById("pagination-first-page").disabled){
                        console.log('filter change..')
                        calluserlist();
                    }else{
                      document.getElementById("pagination-first-page").click();
                    }
                  }else{
                    // console.log('filter else change..')
                    calluserlist();
                  }
          }
      },[filter]);

    useEffect(() => {
        debugger
            if (userResapi != null) {
                var data = userResapi.data;
                data.map((i,index)=>i.srno=index+1)
                setuserRes(userResapi.data);
                setuserscount(userResapi.count);
            }
    }, [userResapi]);
    useEffect(() => {
        debugger
        if (userRes != null) {
            setuserRes(userRes);
            setuserscount(userResapi.count);
        }
    }, [userstateapi]);

    function searchfilter(e) {
        debugger
        localStorage.removeItem("searchtext")
        setfilter(e.target.value.trim()==''?"":e.target.value)
    }
    return (<>
        <Loader />
        <div className="wrapper">
            <Navbar page="user" />

            <div id="content-page" className="content-page">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="iq-card">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title">User's List</h4>
                                    </div>
                                </div>
                                <div className="iq-card-body">
                                    <div className="table-responsive">
                                    <div className="custom-table-search "><input type="text" className="form-control custom-search col-3" placeholder="search by name and email" value={filter} onChange={(e) => searchfilter(e)} /></div>
                                                
                                        {userRes ? userRes.length > 0 ?
                                            //    <> 
                                               <DataTable
                                                    fixedHeader={true}
                                                    columns={columns}
                                                    data={userRes}
                                                    
                                                    // sortIcon={<i style={{marginLeft:5}} className="fa fa-sort" aria-hidden="true"></i>}
                                                    pagination
                                                    paginationDefaultPage ={page}
                                                    highlightOnHover
                                                    paginationServer
                                                    paginationResetDefaultPage={true}
                                                    paginationTotalRows={userscount}
                                                    paginationPerPage={countPerPage}
                                                    paginationComponentOptions={{
                                                      noRowsPerPage: true
                                                    }}
                                                    // rowsPerPage={countPerPage}
                                                    // currentPage={page}
                                                    onChangePage={page => {setPage(page);localStorage.removeItem("pagenum")}}
                                                />
                                                //  <span>{(page * countPerPage)- countPerPage + 1 +'-'}</span> 
                                                // <span>{page * countPerPage <userscount ? page * countPerPage : userscount}</span> 
                                                // <span>{' of ' + userscount }</span></> 
                                            : <p style={{textAlign:'center',padding:10}}>No Data Found</p> : <div className="col-sm-12 text-center" style={{ marginBottom: 50 }}>
                                            <img src={pageloadloader} style={{ height: 80 }} />
                                        </div>}

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Modal
                classNames={{
                    overlay: "customOverlay",
                    modal: "customModal"
                }}
                open={open}
                center
                onClose={() => { }}
                showCloseIcon={false}
            >
                {open &&
                    <div className="modal fade show create-post liked-by-user" id="post-modal" tabIndex="-1" role="dialog" aria-labelledby="post-modalLabel" aria-modal="true" style={{ paddingRight: '8', display: 'block' }}>
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                
                                <h5 className="modal-title" id="post-modalLabel">Profile Picture</h5>
                               
                                
                               <div className="custom-modal-close"><button type="button" className="btn  close-modal" data-dismiss="modal" onClick={() => { setopen(false); setuserphoto("") }}><i className="ri-close-fill"></i></button></div>
                                </div>
                                <div className="modal-body">
                                    <Img
                                        className="img-fluid img-rounded user-manage-img"
                                        style={{ height: 300, width: 300 }}
                                        src={userphoto}
                                        loader={<img src={user09} className="img-fluid" style={{ height: 300, width: 300 }} />}
                                        unloader={<img src={user09} className="img-fluid" style={{ height: 300, width: 300 }} />}
                                    />
                                </div>
                            </div>
                        </div></div>
                }
            </Modal>
            {open && <div className="modal-backdrop fade show"></div>}
        </div>
<Footer/>
    </>
    )
}

export default Usermanage