import React from "react";
import { connect } from "react-redux";
import { role } from "../constants";
import { PostDetailAPI, UPVoteListAPI,DownVoteListAPI } from "../actions/globalactions";
import Navbar from './Navbar';
import Linkify from 'react-linkify';
import { diff_minutes, numFormatter } from '../utils'
import { Img } from 'react-image';
import user09 from "../asset/images/user1.svg";
import pageloadloader from "../asset/images/page-load-loader.gif";
import Modal from "react-responsive-modal";
class PostDetailAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            postdetails: null,
            is_openlike: false,
            is_opendislike:false,
            likeList: null,
            dislikeList:null,
            nodata: false
        }
    }
    componentDidMount() {
        var admintoken = localStorage.getItem("admintoken")
        if (!admintoken) {
            this.props.history.push(`/login`);
        } else {
            if (this.props) {
                var postid = this.props.match.params.id ? this.props.match.params.id : this.props.location.state.postid
                if (postid) {
                    this.props.PostDetailAPI({
                        "post_id": postid,
                        "device_type": role
                    });
                }
            }
        }
    }
    componentWillReceiveProps(nextprops) {
        if (nextprops.postdetail) {
            if (nextprops.postdetail != this.props.postdetail) {
                debugger
                if (nextprops.postdetail.success) {
                    this.setState({ postdetails: nextprops.postdetail.data, nodata: false })
                } else {
                    this.setState({ nodata: true })
                }

            }
        }
        if (nextprops.likeListres) {
            if (nextprops.likeListres != this.props.likeListres) {
                debugger
                this.setState({ likeList: nextprops.likeListres.data })
            }
        }

        if (nextprops.dislikeListres) {
            if (nextprops.dislikeListres != this.props.dislikeListres) {
                debugger
                this.setState({ dislikeList: nextprops.dislikeListres.data })
            }
        }
        
    }
    Openlike = (id) => {
        this.setState({ is_openlike: true })
        if (!this.state.likeList) {
            const data = {
                "post_id": id,
                "device_type": role
            }
            this.props.UPVoteListAPI(data)
        }
    }

    Opendislike = (id) => {
        this.setState({ is_opendislike: true })
        if (!this.state.dislikeList) {
            const data = {
                "post_id": id,
                "device_type": role
            }
            this.props.DownVoteListAPI(data)
        }
    }
    render() {
        debugger
        var i = this.state.postdetails
        var comnt = i ? i.comment ? i.comment.length > 1 ?
            i.comment.length + " Comments" :
            i.comment.length > 0 ? i.comment.length + " Comment" : "" :
            i.total_comments > 1 ?
                numFormatter(i.total_comments) + " Comments" :
                i.total_comments == 1 ?
                    i.total_comments + " Comment" :
                    "" : ""
        var like = i ? i.total_up_votes > 1 ?
            numFormatter(i.total_up_votes) + " Likes" :
            i.total_up_votes == 1 ?
                i.total_up_votes + " Like" :
                "" : ""
        var dislike = i ? i.total_down_votes > 1 ?
            numFormatter(i.total_down_votes) + " Dislikes" :
            i.total_down_votes == 1 ?
                i.total_down_votes + " Dislike" :
                "" : ""
        return (
            <div className="wrapper">
                <Navbar page="" />
                <div id="content-page" className="content-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="iq-card">
                                    <div className="iq-card-header d-flex">
                                        <h4 className="card-title">Post's Details</h4>
                                    </div>

                                    {i && !this.state.nodata ? <div className="iq-card-body " >
                                        <div className="user-post-data">
                                            <div className="d-flex flex-wrap align-items-center">
                                                <a className="media-support-user-img mr-2 ">
                                                    <img className="rounded-circle img-fluid" src={i.user_data.photo} />
                                                </a>
                                                <div className="media-support-info mt-2 " >
                                                    <div className="feeling-user">
                                                        {i.threadData ?
                                                            <h5 className="mb-0 d-inline-block "><a>{i.threadData.name}</a></h5>
                                                            : <h5 className="mb-0 d-inline-block "><a>{i.user_data.name}</a></h5>
                                                        }
                                                        {i.emotion_name && <p className="feeling-name">is feeling {i.emotion_icon && <img src={i.emotion_icon} className="rounded-circle" />} <span className="post-emotion-name"> {i.emotion_name}</span> </p>}
                                                    </div>
                                                    {i.threadData && <span className="post-user-name">{i.threadData.username}</span>}
                                                    <p className="mb-0 post-time"><span className="">{diff_minutes(i.created_at)}</span></p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-2 home-card-desc" >
                                            <Linkify
                                                componentDecorator={(decoratedHref, decoratedText, key) => (
                                                    <a target="blank" href={decoratedHref} key={key}>
                                                        {decoratedText}
                                                    </a>
                                                )}
                                            >{i.content.split("\n").map(function (item, idx) {
                                                return (
                                                    <p className="text-post-content" key={idx}>
                                                        {item}
                                                        <br />
                                                    </p>
                                                )
                                            })
                                                } </Linkify>
                                        </div>
                                        

                                            <div className="comment-area ">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="like-block position-relative d-flex align-items-center">
                                                         <div className="d-flex align-items-center">
                                                            <div className="total-comment-block ">
                                                                <div className="dropdown">
                                                                    <div className="">
                                                                            <span>
                                                                                {comnt==""?'0 Comment':comnt}
                                                                            </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        {/* {!this.state.is_openlike && */}
                                                            <div className="total-comment-block">
                                                                <div className="dropdown" >
                                                                    <span className="separator-icon">  <i className="fa fa-circle"></i>    </span>
                                                                    
                                                                            {/* className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button" */}
                                                                            {like==""?
                                                                            <span className="no-like">0 Like</span>
                                                                            :<a onClick={() => this.Openlike(i._id)} className="like-link">{like}</a>}
                                                                </div>
                                                            </div>
                                                            {/* } */}

                                                            {/* {!this.state.is_opendislike && */}
                                                            <div className="total-comment-block">
                                                                <div className="dropdown" >
                                                                    <span className="separator-icon">  <i className="fa fa-circle"></i>    </span>
                                                                    
                                                                            {/* className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button" */}
                                                                            {dislike==""?
                                                                            <span className="no-like">0 Dislike</span>
                                                                            :<a onClick={() => this.Opendislike(i._id)} className="like-link">{dislike}</a>}
                                                                </div>
                                                            </div>
                                                            {/* } */}
                                                            
                                                    </div>
                                                </div>
                                            </div>




                                        {i.comment &&
                                            i.comment.length > 0 ?
                                            <>
                                                <hr className="post-card-hr"></hr>
                                                <ul className="post-comments p-0 m-0">
                                                    {i.comment.map((j, index) =>
                                                        <li className="mb-2" id={'cmt-' + j._id} key={j._id}>
                                                            <div className="d-flex flex-wrap">
                                                                <a className="user-img" >
                                                                    <Img
                                                                        className="avatar-50 rounded-circle img-fluid"
                                                                        src={j.user_data.photo}
                                                                        loader={<img src={user09} className="avatar-50 rounded-circle img-fluid" />}
                                                                        unloader={<img src={user09} className="avatar-50 rounded-circle img-fluid" />}
                                                                    />
                                                                </a>
                                                                <div className={"comment-data-block "}>
                                                                    <div className="time-profile-name">   <h6>{j.user_data.name}</h6>
                                                                        <span>{diff_minutes(j.created_at)}</span>
                                                                    </div>
                                                                    <div className=" comment-data-sec">
                                                                        <Linkify
                                                                            componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                                <a target="blank" href={decoratedHref} key={key}>
                                                                                    {decoratedText}
                                                                                </a>
                                                                            )}
                                                                        >
                                                                            {
                                                                                j.message.split("\n").map(function (item, idx) {
                                                                                    return (
                                                                                        <p className="mb-0" key={idx}>
                                                                                            {item}
                                                                                            <br />
                                                                                        </p>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Linkify>
                                                                    </div>
                                                                    <div className="comment-like-div">
                                                                        {j.total_up_votes ? <span>{numFormatter(j.total_up_votes)}{j.total_up_votes > 1 ? ' Likes' : ' Like'}</span> : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>

                                                    )} </ul></> : null}

                                        <Modal
                                            classNames={{
                                                overlay: "customOverlay",
                                                modal: "customModal"
                                            }}
                                            open={this.state.is_openlike}
                                            center
                                            onClose={() => { }}
                                            showCloseIcon={false}
                                        >
                                            {this.state.is_openlike &&
                                                <div className="modal fade show liked-by-user" id="post-modal" tabIndex="-1" role="dialog" aria-labelledby="post-modalLabel" aria-modal="true" style={{ paddingRight: '8', display: 'block' }}>
                                                    <div className="modal-dialog" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="post-modalLabel">Likes</h5>
                                                                <button type="button" className="btn  close-modal" data-dismiss="modal" onClick={() => this.setState({ is_openlike: false })}><i className="ri-close-fill"></i></button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className="like-lists-sec">
                                                                    {this.state.likeList ? this.state.likeList.length > 0 ?
                                                                        this.state.likeList.map((i, index) =>
                                                                            <div className="who-has-liked" key={i._id}>
                                                                                <Img
                                                                                    className="rounded-circle img-fluid user-like-img"
                                                                                    src={i.photo}
                                                                                    loader={<img src={user09} className="rounded-circle img-fluid user-like-img" />}
                                                                                    unloader={<img src={user09} className="rounded-circle img-fluid user-like-img" />}
                                                                                />
                                                                                {/* {i.photo!=""?<img className="rounded-circle img-fluid user-like-img" src={i.photo}  />:<img className="rounded-circle img-fluid user-like-img" src={user09}  />} */}
                                                                                <div> <p className="user-like-name">{i.name}</p>
                                                                                    <p className="user-like-name  font-size-12">{i.username}</p>
                                                                                </div>
                                                                            </div>)
                                                                        : null : <div className="col-sm-12 text-center">
                                                                        <img src={pageloadloader} style={{ height: 80 }} />
                                                                    </div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </Modal>
                                        <Modal
                                            classNames={{
                                                overlay: "customOverlay",
                                                modal: "customModal"
                                            }}
                                            open={this.state.is_opendislike}
                                            center
                                            onClose={() => { }}
                                            showCloseIcon={false}
                                        >
                                            {this.state.is_opendislike &&
                                                <div className="modal fade show liked-by-user" id="post-modal" tabIndex="-1" role="dialog" aria-labelledby="post-modalLabel" aria-modal="true" style={{ paddingRight: '8', display: 'block' }}>
                                                    <div className="modal-dialog" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="post-modalLabel">Dislikes</h5>
                                                                <button type="button" className="btn  close-modal" data-dismiss="modal" onClick={() => this.setState({ is_opendislike: false })}><i className="ri-close-fill"></i></button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className="like-lists-sec">
                                                                    {this.state.dislikeList ? this.state.dislikeList.length > 0 ?
                                                                        this.state.dislikeList.map((i, index) =>
                                                                            <div className="who-has-liked" key={i._id}>
                                                                                <Img
                                                                                    className="rounded-circle img-fluid user-like-img"
                                                                                    src={i.photo}
                                                                                    loader={<img src={user09} className="rounded-circle img-fluid user-like-img" />}
                                                                                    unloader={<img src={user09} className="rounded-circle img-fluid user-like-img" />}
                                                                                />
                                                                                {/* {i.photo!=""?<img className="rounded-circle img-fluid user-like-img" src={i.photo}  />:<img className="rounded-circle img-fluid user-like-img" src={user09}  />} */}
                                                                                <div> <p className="user-like-name">{i.name}</p>
                                                                                    <p className="user-like-name  font-size-12">{i.username}</p>
                                                                                </div>
                                                                            </div>)
                                                                        : null : <div className="col-sm-12 text-center">
                                                                        <img src={pageloadloader} style={{ height: 80 }} />
                                                                    </div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </Modal>
                                        {(this.state.is_openlike || this.state.is_opendislike) && <div className="modal-backdrop fade show"> </div>}
                                    </div>
                                        : !this.state.nodata ?
                                            <div className="col-sm-12 text-center">
                                                <img src={pageloadloader} style={{ height: 80 }} />
                                            </div>
                                            : <p>No Post Details</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        PostDetailAPI: (postdata) => { dispatch(PostDetailAPI(postdata)) },
        UPVoteListAPI: (postdata) => { dispatch(UPVoteListAPI(postdata)) },
        DownVoteListAPI: (postdata) => { dispatch(DownVoteListAPI(postdata)) }
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        postdetail: state.globalReducer.PostDetailRes,
        likeListres: state.globalReducer.UPVoteListRes,
        dislikeListres: state.globalReducer.DownVoteListRes
    }
}
export default connect(mapStateToProps, mapDispatchToProps)((PostDetailAdmin))
