import React from "react";
import { connect } from "react-redux";
import Navbar from './Navbar';
import {DashboardAPI} from "../actions/globalactions";
import { dispatchLoadingStatus } from '../utils';
import AdminChart from './Charts';
import pageloadloader from "../asset/images/page-load-loader.gif";
import Loader from './Loader';
import Footer from './Footer';

class Dashboard extends React.Component {
    componentDidMount() {
        var admintoken = localStorage.getItem("admintoken")
        if (!admintoken) {
          this.props.history.push(`/login`);
        }else{
          this.props.isLoading(true);
          this.props.DashboardAPI();
        }
        
      }

      render() {
        return (  <>
        <Loader/>
        <div className="wrapper">
           <Navbar page="dashboard"/>
        <div id="content-page" className="content-page">
            <div className="container">
             <div className="row">
                <div className="col-md-6 col-lg-3">
                          <div className="iq-card iq-card-block iq-card-stretch iq-card-height iq-border-box iq-border-box-1 text-primary">
                             <div className="iq-card-body">
                                <div className="d-flex align-items-center justify-content-between"> 
                                   <h5>Total Users</h5>
                                   <h3>{this.props.dashboardRes?this.props.dashboardRes.total_users:0}</h3>
                                 </div>
                             </div>
                          </div>
                       </div>
                       <div className="col-md-6 col-lg-3">
                          <div className="iq-card iq-card-block iq-card-stretch iq-card-height iq-border-box iq-border-box-2 text-warning">
                             <div className="iq-card-body">
                                <div className="d-flex align-items-center justify-content-between"> 
                                   <h5>Active Users</h5>
                                   <h3>{this.props.dashboardRes?this.props.dashboardRes.total_active_users:0}</h3>
                                 </div>
                             </div>
                          </div>
                       </div>
                       <div className="col-md-6 col-lg-3">
                          <div className="iq-card iq-card-block iq-card-stretch iq-card-height iq-border-box iq-border-box-3 text-danger">
                             <div className="iq-card-body">
                                <div className="d-flex align-items-center justify-content-between"> 
                                   <h5>Inactive Users</h5>
                                   <h3>{this.props.dashboardRes?this.props.dashboardRes.total_inactive_users:0}</h3>
                                 </div>
                             </div>
                          </div>
                       </div>
                       <div className="col-md-6 col-lg-3">
                          <div className="iq-card iq-card-block iq-card-stretch iq-card-height iq-border-box iq-border-box-4 text-info">
                             <div className="iq-card-body">
                                <div className="d-flex align-items-center justify-content-between"> 
                                   <h5>Today's New Users</h5>
                                   <h3>{this.props.dashboardRes?this.props.dashboardRes.new_users:0}</h3>
                                 </div>
                             </div>
                          </div>
                       </div>
                <div className="col-lg-6">
                          <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                              <div className="iq-card-header d-flex justify-content-between">
                              <div className="iq-header-title">
                                 <h4 className="card-title">Weekly Registered Users</h4>
                              </div>
                           </div>
                             <div className="iq-card-body">
                             {this.props.dashboardRes?<AdminChart datan={this.props.dashboardRes.weekly_register_user} tooltip={"Registered users"} color={'#0183fb'}/>: <div className="col-sm-12 text-center" style={{ marginBottom: 50 }}>
                              <img src={pageloadloader} style={{ height: 80 }} />
                           </div>}
                              </div>
                           </div>
                        </div>
                       <div className="col-lg-6">
                          <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                              <div className="iq-card-header d-flex justify-content-between">
                              <div className="iq-header-title">
                                 <h4 className="card-title">Weekly Added Posts</h4>
                              </div>
                           </div>
                             <div className="iq-card-body">
                             {this.props.dashboardRes?<AdminChart datan={this.props.dashboardRes.weekly_register_post} tooltip={"Added posts"} color={'#ffd506'}/>: <div className="col-sm-12 text-center" style={{ marginBottom: 50 }}>
                              <img src={pageloadloader} style={{ height: 80 }} />
                           </div>}

                             </div>
                          </div>
                       </div>
             </div>
            </div>
         </div>
      </div>
      <Footer/>
 
      
      </>
      )
  }
}
      const mapDispatchToProps = (dispatch, ownProps) => {
        return {
                    isLoading: loading => {
                    dispatch(dispatchLoadingStatus(loading));
          },
          DashboardAPI:()=>{dispatch(DashboardAPI())}
        }
      }
      const mapStateToProps = (state, ownProps) => {
        return {
                    loading: state.LoadingStatusReducer.loading,
                    dashboardRes:state.globalReducer.DashboardRes
        }
      }
      export default connect(mapStateToProps, mapDispatchToProps)((Dashboard))